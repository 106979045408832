import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { VTimePicker } from 'vuetify/labs/components'
import 'vuetify/styles'
import { VBtn, VColorPicker } from 'vuetify/components'

const customTheme = {
  dark: false,
  colors: {
    white: '#FFFFFF',
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#8DC63F',
    primaryHover: '#4CAF50',
    primaryHoverOutline: '#F1F8E9;',
    secondary: '#4A4A4A',
    info: '#16b1ff',
    warning: '#e6a200',
    green: '#8DC63F',
    success: '#1CE45A',
    error: '#F1002E',
    alert: '#FF9500',
    'grey-0': '#272727',
    'grey-1': '#3a3a3a',
    'grey-2': '#4A4A4A',
    'grey-3': '#979797',
    'grey-4': '#BBBBBB',
    'grey-5': '#D5D5D5',
    'grey-6': '#E5E5E5',
    'grey-7': '#F6F7FA',
    'grey-8': '#D8D8D8',
    'grey-9': '#555555',
    'grey-10': '#9B9B9B',
    'grey-11': '#727779',
    'black-0': '#272727',
    'blue-0': '#2CABE3',
    events: '#272727',
    sponsor: '#C40000',
    supplier: '#00447C',
    partner: '#0D9F8E',
    'rating-red': '#E40000',
    'rating-dark-orange': '#f25000',
    'rating-orange': '#ff9500',
    'rating-yellow': '#f0c300',
    'rating-green': '#69ce00',
  },
}

export const vuetify = createVuetify({
  theme: {
    defaultTheme: 'customTheme',
    themes: {
      customTheme,
    },
  },
  components: {
    VTimePicker,
    VBtn,
    VColorPicker,
  },
})
