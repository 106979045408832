<script setup lang="ts">
import { useAttrs, computed } from 'vue'
import { VBtn } from 'vuetify/components'

const props = defineProps<{
  loading?: boolean
  leftIcon?: string
  rightIcon?: string
  color?: string
  outline?: boolean
}>()

const attrs = useAttrs()

// Computed property para definir a classe de estilo do botão com base no 'outline'
const buttonClass = computed(() => {
  return props.outline ? 'primaryOutline' : 'primaryFlat'
})
</script>

<template>
  <VBtn
    v-bind="attrs"
    :id="String($attrs.id)"
    :loading="props.loading"
    :color="props.color || 'primary'"
    :class="buttonClass"
    variant="outlined"
    size="default"
    elevation="0"
    class="d-flex align-center pa-3 rounded-lg transition-all"
  >
    <slot />
  </VBtn>
</template>

<style scoped>
.primaryFlat {
  background-color: #8dc63f !important;
  color: white !important;
  border-color: #8dc63f !important;
}

.primaryFlat:hover {
  background-color: #4caf50 !important;
  color: white !important;
  border-color: #4caf50 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* elevation-1 */
}

.primaryOutline {
  background-color: transparent !important;
  color: #8dc63f !important;
  border-color: #8dc63f !important;
}

.primaryOutline:hover {
  background-color: #f1f8e9 !important;
  color: #8dc63f !important;
  border-color: #8dc63f !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* elevation-1 */
}

.cancelFlat {
  background-color: #ff5252 !important;
  color: white !important;
  border-color: #ff5252 !important;
}

.cancelFlat:hover {
  background-color: #ff5252 !important;
  color: white !important;
  border-color: #ff5252 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* elevation-1 */
}

.cancelOutline {
  background-color: transparent !important;
  color: #ff5252 !important;
  border-color: #ff5252 !important;
}

.cancelOutline:hover {
  background-color: #f1f8e9 !important;
  color: #ff5252 !important;
  border-color: #ff5252 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* elevation-1 */
}

.disableFlat {
  background-color: #e0e0e0 !important;
  color: #a6a6a6 !important;
  border-color: #e0e0e0 !important;
}

.disableFlat:hover {
  background-color: #e0e0e0 !important;
  color: #a6a6a6 !important;
  border-color: #e0e0e0 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* elevation-1 */
}

.disableOutline {
  background-color: transparent !important;
  color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
}

.disableOutline:hover {
  background-color: #f1f8e9 !important;
  color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* elevation-1 */
}
</style>
