<script setup lang="ts">
import favoriteGreenIcon from '@/assets/icons/green/favorite.svg'
import EditDataSectionItemMenu from '@/components/EditDataSectionItemMenu.vue'
import Dialog from '@/components/Dialog.vue'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import { FieldArray, FieldEntry } from 'vee-validate'
import { reactive, ref } from 'vue'

interface EditDataSectionProps {
  title: string
  actionLabel: string
  section: string
  defaultLabel: string
  defaultItemValue: unknown
}

type AddressFieldValue = { label?: string }

const props = defineProps<EditDataSectionProps>()
const emit = defineEmits(['edit-label'])

const modals = reactive({ editLabel: false })
const selectedField = ref<number>()
const labelBeingEdited = ref(props.defaultLabel)

function openEditLabelDialog(field: FieldEntry, idx: number) {
  modals.editLabel = true
  labelBeingEdited.value =
    (field.value as AddressFieldValue)?.label ?? props.defaultLabel
  selectedField.value = idx
}

function saveLabel() {
  emit('edit-label', {
    idx: selectedField.value,
    label: labelBeingEdited.value,
  })
  selectedField.value = undefined
  labelBeingEdited.value = ''
  modals.editLabel = false
}
</script>

<template>
  <FieldArray v-slot="{ fields, push, remove, move }" :name="props.section">
    <v-container fluid class="pa-0">
      <!-- Cabeçalho -->
      <v-row class="mb-2" justify="space-between" no-gutters>
        <v-col cols="auto">
          <h3 class="text-h6 font-weight-bold mb-0">{{ props.title }}</h3>
        </v-col>
        <v-col cols="auto">
          <Button
            :id="`${props.section}-add-new-item-button`"
            color="primary"
            @click="() => push(props.defaultItemValue || {})"
          >
            {{ props.actionLabel }}
          </Button>
        </v-col>
      </v-row>

      <!-- Itens -->
      <v-row
        v-for="(field, idx) in fields"
        :id="`edit-menu-${props.section}-${idx}`"
        :key="field.key"
        no-gutters
        class="mb-3"
      >
        <v-col cols="12">
          <v-row align="center" justify="space-between" no-gutters>
            <v-col cols="auto" class="d-flex align-center">
              <p class="font-weight-bold text-body-2 mb-0">
                {{
                  (field.value as AddressFieldValue).label ||
                  `${props.defaultLabel} ${idx + 1}`
                }}
              </p>
              <v-img
                v-if="idx === 0"
                :src="favoriteGreenIcon"
                alt="Favorito"
                max-width="20"
                class="ms-2"
              />
            </v-col>
            <v-col cols="auto">
              <EditDataSectionItemMenu
                :id="idx"
                :section="props.section"
                :move="move"
                :push="push"
                :remove="
                  () => {
                    remove(idx)
                    if (idx === 0 && fields.length === 0)
                      push(props.defaultItemValue || {})
                  }
                "
                :field-length="fields.length"
                @edit-label="openEditLabelDialog(field, idx)"
              />
            </v-col>
          </v-row>

          <!-- Slot para campos do item -->
          <slot :idx="idx" :field="field" />
        </v-col>
      </v-row>
    </v-container>
  </FieldArray>

  <!-- Diálogo de edição -->
  <Dialog
    :open="modals.editLabel"
    :title="$t('corporate.edit.editLabelTitle')"
    @close="modals.editLabel = false"
  >
    <v-container fluid class="my-4">
      <v-row>
        <v-col cols="12">
          <Input
            id="edit-label-dialog-input"
            v-model="labelBeingEdited"
            :label="$t('corporate.edit.editLabelField')"
          />
        </v-col>
      </v-row>
    </v-container>

    <template #actions>
      <v-row justify="center">
        <v-col cols="6">
          <Button
            id="edit-label-dialog-button"
            type="button"
            class="w-100 mb-5"
            @click="saveLabel"
          >
            {{ $t('general.save') }}
          </Button>
        </v-col>
      </v-row>
    </template>
  </Dialog>
</template>
