<script setup lang="ts">
import warningIcon from '@/assets/icons/white/icon-warning.png'
import infoIcon from '@/assets/icons/white/info.svg'
import AlertDialog from '@/components/AlertDialog.vue'
import { usePromise } from '@/composables/usePromise'
import Page from '@/layouts/Page.vue'
import { MessageSchema } from '@/plugins/i18n'
import { useCorporativeAccount } from '@/store/corporative'
import { isValidNameZodRule } from '@/utils/isValidName'
import { toTypedSchema } from '@vee-validate/zod'
import { storeToRefs } from 'pinia'
import { useForm } from 'vee-validate'
import { computed, onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import { z } from 'zod'

const REQUEST_RECIPIENT = 'desenvolvimento@it4cio.com'

const { t } = useI18n<MessageSchema>()
const router = useRouter()
const corporateStore = useCorporativeAccount()
const { createCorporativeAccount, getTerms, requestAccess } = corporateStore
const { terms } = storeToRefs(corporateStore)

const selectedDocType = ref('cnpj')
const maskOptions = computed(() => ({
  mask: selectedDocType.value == 'cnpj' ? '##.###.###/####-##' : null,
}))

const acceptedTerms = ref(false)
const duplicateCNPJText = ref('')
const duplicateCNPJId = ref('')
const toast = useToast()
const { handleSubmit, meta, isSubmitting } = useForm({
  validationSchema: toTypedSchema(
    z.object({
      name: isValidNameZodRule.refine((value) => value.length <= 150, {
        message: t('validation.nameLength', { length: 150 }),
      }),
      tradingName: isValidNameZodRule.refine((value) => value.length <= 60, {
        message: t('validation.nameLength', { length: 60 }),
      }),
      document: z
        .string()
        .refine((v) =>
          selectedDocType.value == 'cnpj'
            ? /^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2})$/.test(v)
            : !!v
        ),
      corporateEmail: z.string().email(),
    })
  ),
})
const promiseHandler = usePromise(t)
const handleCreate = handleSubmit(
  async (values) => {
    if (values.document) {
      values.document = values.document.replace(/[./-]/g, '')
    }

    promiseHandler(createCorporativeAccount(values), [
      () => {
        toast.success(t('corporate.create.success'))
        router.push({ name: 'home' })
      },
      (error) => {
        const documentError = error.fieldErrors?.find(
          (e) => e.field == 'document'
        )
        if (documentError && documentError.message) {
          toast.error(t('corporate.create.invalidCNPJ'))
          return
        }
        if (error.detail) {
          duplicateCNPJText.value = error.detail as string
          const corporativeId = (error.instance as string).split('/')[3]
          if (!corporativeId)
            return toast.error(t('general.messageProblemRequest'))
          duplicateCNPJId.value = corporativeId
          modals.duplicateCNPJ = true
          return
        }
        return toast.error(t('general.messageProblemRequest'))
      },
    ])
  },
  ({ values, errors, results }) => {
    console.log(values)
    console.log(errors)
    console.log(results)
  }
)
const modals = reactive({
  duplicateCNPJ: false,
  claimAccount: false,
  terms: false,
})

function openClaimModal() {
  modals.duplicateCNPJ = false
  modals.claimAccount = true
}

function handleAccept() {
  modals.terms = false
  acceptedTerms.value = true
}

async function requestCorporateAccountAccess() {
  const corporativeId = parseInt(duplicateCNPJId.value, 10)

  try {
    await requestAccess(corporativeId)
    toast.success(t('corporate.create.requestAccessSuccess'))
  } catch (error) {
    toast.error(t('corporate.create.requestAccessFailure'))
  } finally {
    duplicateCNPJId.value = ''
    modals.duplicateCNPJ = false
  }
}

onMounted(() => getTerms())
</script>
<template>
  <Page>
    <template #page-title>
      <PageHeader :title="t('corporate.create.title')" :back-button="true" />
    </template>
    <div class="my-0 grid grid-cols-2 p-8">
      <div class="col-span-1">
        <h3 class="mb-4 mt-0 font-bold">
          {{ t('corporate.create.subtitle') }}
        </h3>
        <FormInput
          id="create-corporative-account-name"
          name="name"
          :label="t('corporate.create.fields[0]')"
        />
        <FormInput
          id="create-corporative-account-trading-name"
          name="tradingName"
          :label="t('corporate.create.fields[1]')"
        />

        <div class="flex gap-2">
          <Select
            id="create-corporative-account-select-doc-type"
            v-model="selectedDocType"
            :label="t('corporate.create.fields[2]')"
            name="selectedDocType"
            rules="required"
            :items="[{ label: 'CNPJ', value: 'cnpj' }]"
            label-field="label"
          />

          <FormInput
            id="create-corporative-account-doc"
            v-maska="maskOptions"
            name="document"
            :label="t('corporate.create.fields[4]')"
          />
        </div>
        <FormInput
          id="create-corporative-account-corporative-email"
          name="corporateEmail"
          :label="t('corporate.create.fields[5]')"
          type="email"
        />
        <div class="mt-4 flex flex-row items-center justify-start">
          <Input v-model="acceptedTerms" type="checkbox" class="mr-1 w-min" />
          <p class="ml-8">{{ $t('corporate.terms.checkbox') }}</p>
          <span
            class="ml-1 cursor-pointer underline"
            @click="modals.terms = true"
          >
            {{ $t('corporate.terms.useTerms') }}</span
          >
        </div>
      </div>
      <Dialog
        :open="modals.terms"
        :title="$t('corporate.terms.title')"
        @close="modals.terms = false"
      >
        <div
          class="mx-6 my-8 max-h-[30vh] overflow-y-auto pr-6 text-justify"
          v-html="terms"
        ></div>
        <div class="flex justify-center border-t-2 border-grey-6 pt-4">
          <Button class="self-center" @click="handleAccept">{{
            $t('corporate.terms.accept')
          }}</Button>
        </div>
      </Dialog>
    </div>
    <AlertDialog
      :is-open="modals.duplicateCNPJ"
      :icon="warningIcon"
      color="alert"
      :title="$t('general.advise')"
      class="text-bold"
      @close="modals.duplicateCNPJ = false"
    >
      <div class="flex flex-col items-center justify-center gap-2">
        <p
          id="duplicate-cnpj-error-message"
          class="text-center"
          v-html="duplicateCNPJText"
        ></p>
        <Button
          class="min-w-[22rem] bg-alert text-base"
          @click="requestCorporateAccountAccess()"
          >{{ t('corporate.create.requestAccess') }}
        </Button>
        <Button
          class="min-w-[22rem] bg-grey-3 text-base"
          @click="openClaimModal"
          >{{ t('corporate.create.claimAccess') }}</Button
        >
      </div>
    </AlertDialog>

    <AlertDialog
      :is-open="modals.claimAccount"
      :icon="infoIcon"
      color="green"
      :text="$t('corporate.create.messageClaimAccount1')"
      :title="$t('corporate.create.claimAccount')"
      class="!min-w-full !px-10 text-sm"
      @close="modals.claimAccount = false"
    >
      <div class="flex flex-col px-10 pb-4 text-center">
        {{ $t('corporate.create.messageClaimAccount2') }} <br />
        {{ $t('corporate.create.messageClaimAccount3') }}
      </div>
      <Button class="my-4 !min-w-[22rem] text-base"
        ><a :href="`mailto:${REQUEST_RECIPIENT}`">{{
          $t('corporate.create.requestSupport')
        }}</a></Button
      >
    </AlertDialog>
    <template #footer>
      <Button class="bg-grey-3" @click="router.back()">{{
        t('general.cancel')
      }}</Button>
      <Button
        id="create-corporative-account-save-btn"
        :loading="isSubmitting"
        :disabled="!acceptedTerms || !meta.valid"
        @click="handleCreate"
        >{{ t('general.continue') }}</Button
      >
    </template>
  </Page>
</template>
@/store/store_old
