<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import Dialog from '@/components/Dialog.vue'
import searchIcon from '@/assets/icons/grey/search.svg'
import { initialsPicture } from '@/utils/getInitialsPicture'
import Card from '@/components/Card.vue'
import warningIcon from '@/assets/icons/white/icon-warning.png'
import { Member } from '@/modules/member/types/members'

const props = defineProps<{
  isOpenOwner?: boolean
  members: Member[]
}>()

const searchMember = ref('')
const selectedMember = ref<Member | null>(null)
const emits = defineEmits(['close', 'search'])

const selectMember = (member: Member) => {
  selectedMember.value = selectedMember.value === member ? null : member
}

const saveSelection = () => {
  if (selectedMember.value !== null) {
    emits('close', selectedMember.value.userId)
  }
}

const emptyMembers = computed(
  () => !props.members.length && !searchMember.value
)

const pictureProfile = computed(() =>
  props.members.map((member) => ({
    ...member,
    profilePicture: initialsPicture(member.name),
  }))
)

const getProfilePicture = (memberId: string) => {
  const member = pictureProfile.value.find((m) => m.userId === memberId)
  return member ? member.profilePicture : ''
}

function handleInput(event?: Event) {
  emits('search', (event?.target as HTMLInputElement)?.value)
}

onMounted(() => {
  handleInput()
})

watch(
  () => props.isOpenOwner,
  () => {
    searchMember.value = ''
  }
)
</script>

<template>
  <Dialog
    :open="props.isOpenOwner"
    :title="$t('corporate.detail.transferOwnership')"
    max-width="600"
    @close="$emit('close')"
  >
    <v-container fluid class="py-5">
      <!-- Campo de busca -->
      <v-text-field
        v-model="searchMember"
        :placeholder="$t('corporate.detail.search')"
        :disabled="emptyMembers"
        density="comfortable"
        variant="outlined"
        class="mb-4"
        append-inner-icon="mdi-magnify"
        :append-inner-icon-props="{ icon: searchIcon }"
        @input="handleInput"
      />

      <!-- Lista de membros -->
      <v-sheet height="384" class="pa-2 overflow-auto bg-white" rounded>
        <div
          v-if="emptyMembers"
          class="d-flex align-center h-100 justify-center"
        >
          <Card color="alert" :icon="warningIcon" class="mt-4">
            <h3 class="font-weight-bold py-2 text-center">
              {{ $t('general.advise') }}
            </h3>
            <p class="px-8 pb-4 text-center">
              {{ $t('corporate.detail.messageTransferOwnership') }}
            </p>
          </Card>
        </div>

        <v-list v-else>
          <v-list-item
            v-for="member in members"
            :key="member.userId"
            class="mb-2"
            :class="{
              'bg-grey-lighten-4': selectedMember === member,
            }"
            @click="selectMember(member)"
          >
            <template #prepend>
              <v-avatar
                :image="getProfilePicture(member.userId)"
                size="44"
                class="me-4"
                :class="{
                  'border border-4 border-success': selectedMember === member,
                }"
              />
            </template>

            <v-list-item-title
              :class="{
                'text-success': selectedMember === member,
                'text-grey-darken-1': selectedMember !== member,
              }"
              class="font-weight-bold text-sm"
            >
              {{ member.firstName + ' ' + member.lastName }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="text-caption text-grey-darken-1 fst-italic"
            >
              {{ member.permissionPresetDTO?.name }}
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-sheet>

      <!-- Botão transferir -->
      <v-row class="mt-5" justify="center">
        <v-col cols="auto">
          <Button :disabled="selectedMember === null" @click="saveSelection">
            {{ $t('corporate.detail.transfer') }}
          </Button>
        </v-col>
      </v-row>
    </v-container>
  </Dialog>
</template>
