<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import orderIconBlack from '@/assets/icons/black/order.svg'
import orderIconDown from '@/assets/icons/green/order_down.svg'
import orderIconUp from '@/assets/icons/green/order_up.svg'
import { DefalultOrder } from '@/services/types'
import OrderListbox from './OrderListbox.vue'
import Button from '@/components/Button.vue'

const props = defineProps<{
  order: string | null
  orderBy: string | null
  multiple?: boolean
  orderingOptions: { value: string; label: string }[]
  defaultOrder: DefalultOrder
}>()

const emit = defineEmits<{
  (e: 'update:orderBy', value: string | null): void
  (e: 'order', value: string): void
  (e: 'clear-ordering'): void
  (e: 'close'): void
}>()

const localOrder = ref<string>(props.order ?? '')
const secondaryLocalOrder = ref<string>(props.order ?? '')
const localOrderBy = ref<string | null>(props.orderBy ?? null)
const secondaryLocalOrderBy = ref<string | null>(null)

watch(localOrderBy, (val) => emit('update:orderBy', val))

const filterOrderIcon = computed(() => {
  if (props.order !== props.defaultOrder.ORDER) {
    return props.defaultOrder.ORDER === 'desc' ? orderIconDown : orderIconUp
  }
  return orderIconBlack
})

function getValueByLabel(label: string | null) {
  const item = props.orderingOptions.find((entry) => entry.label === label)
  return item ? item.value : null
}

function handleOrderAndCloseMenu() {
  const primaryValue = getValueByLabel(localOrderBy.value)
  const secondaryValue = getValueByLabel(secondaryLocalOrderBy.value)

  if (!primaryValue) return

  const orderBy =
    props.multiple && secondaryValue
      ? `${primaryValue},${secondaryValue}`
      : primaryValue

  const order =
    props.multiple && secondaryLocalOrder.value
      ? `${localOrder.value},${secondaryLocalOrder.value}`
      : localOrder.value || ''

  emit('update:orderBy', orderBy)
  emit('order', order)
  emit('close')
}

function updateLocalOrder(val: string) {
  localOrder.value = val
}

function updateSecondaryLocalOrder(val: string) {
  secondaryLocalOrder.value = val
}
</script>

<template>
  <v-menu offset-y>
    <template #activator="{ props: menuProps }">
      <v-btn
        id="order-menu"
        v-bind="menuProps"
        icon
        variant="plain"
        elevation="0"
        density="comfortable"
        :disabled="!orderingOptions?.length"
        :class="{ 'opacity-30': !orderingOptions?.length }"
      >
        <v-img
          :src="filterOrderIcon"
          width="25"
          height="25"
          class="elevation-0"
          cover
        />
      </v-btn>
    </template>

    <v-card width="400" class="pa-4">
      <h3 class="text-subtitle-1 font-weight-bold mb-4">
        {{ $t('corporate.order.modalLabel') }}
      </h3>

      <OrderListbox
        v-model="localOrderBy"
        :order="localOrder"
        :ordering-options="props.orderingOptions"
        @update-order="updateLocalOrder"
      />

      <OrderListbox
        v-if="props.multiple"
        v-model="secondaryLocalOrderBy"
        :order="secondaryLocalOrder"
        :ordering-options="props.orderingOptions"
        is-secondary
        @update-order="updateSecondaryLocalOrder"
      />

      <v-row class="mt-4" :justify="props.multiple ? 'end' : 'center'" dense>
        <v-col cols="auto">
          <Button class="bg-grey-lighten-2" @click="$emit('clear-ordering')">
            {{ $t('corporate.order.clear') }}
          </Button>
        </v-col>
        <v-col cols="auto">
          <Button id="save-order-button" @click="handleOrderAndCloseMenu">
            {{ $t('corporate.order.order') }}
          </Button>
        </v-col>
      </v-row>
    </v-card>
  </v-menu>
</template>
