export const getMockColumnsEvents = () => {
  return [
    { title: 'Nome do evento', key: 'name', sortable: true },
    { title: 'Data', key: 'date', sortable: true },
    { title: 'Cidade', key: 'city', sortable: true },
    { title: 'Estado', key: 'state', sortable: true },
    { title: 'País', key: 'country', sortable: true },
    { title: 'Formato', key: 'format', sortable: true },
    { title: 'Publicação', key: 'publication', sortable: true },
    { title: 'Fase', key: 'phase', sortable: true },
  ]
}
