<script setup lang="ts">
import { computed, toRef } from 'vue'
import { contactTypeClassMap } from '@/modules/contacts/utils/contactTypeClassMap'

const props = defineProps<{ modelValue?: string[] }>()
const emit = defineEmits(['update:modelValue'])

const modelValue = toRef(props, 'modelValue')

const selected = computed(() => modelValue.value ?? [])

const toggleValue = (k: string) => {
  const updated = selected.value.includes(k)
    ? selected.value.filter((v) => v !== k)
    : [...selected.value, k]

  emit('update:modelValue', updated)
}

const chipColor = (k: string) => {
  return selected.value.includes(k)
    ? contactTypeClassMap[k as keyof typeof contactTypeClassMap]
    : 'grey'
}
</script>

<template>
  <div class="d-flex align-center w-100 flex-wrap" style="gap: 8px">
    <v-chip
      v-for="[k] in Object.entries(contactTypeClassMap)"
      :key="k"
      :color="chipColor(k)"
      :class="[
        selected.includes(k)
          ? contactTypeClassMap[k as keyof typeof contactTypeClassMap]
          : '',
        'text-uppercase justify-center font-bold',
      ]"
      style="height: 28px; width: 128px; cursor: pointer; color: white"
      @click="toggleValue(k)"
    >
      {{ $t(`contacts.corporative.${k}`) }}
    </v-chip>
  </div>
</template>
