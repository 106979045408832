<script setup lang="ts">
defineEmits(['close', 'confirm', 'update:isOpen'])
defineOptions({ inheritAttrs: false })

const props = defineProps<{
  isOpen?: boolean
  color?: 'success' | 'error' | 'alert' | 'green'
  text?: string
  title?: string
  icon?: string
}>()
</script>

<template>
  <v-dialog
    :model-value="props.isOpen"
    persistent
    max-width="500px"
    @update:model-value="$emit('update:isOpen', $event)"
  >
    <v-card class="pa-4 rounded-lg text-center">
      <!-- Cabeçalho com ícone -->
      <div
        :class="`bg-${props.color}`"
        class="position-relative mt-3 rounded-t-lg px-1 py-1"
      >
        <div
          class="rounded-circle d-flex align-center elevation-2 justify-center"
          :class="`bg-${props.color}`"
          :style="{
            width: '50px',
            height: '50px',
            backgroundColor: props.color,
            position: 'absolute',
            top: '-25px',
            left: '50%',
            transform: 'translateX(-50%)',
          }"
        >
          <v-img v-if="icon" :src="icon" contain width="30" height="30" />
        </div>
      </div>

      <v-card-title class="text-h5 font-weight-bold mt-2">
        {{ title }}
      </v-card-title>

      <slot></slot>
    </v-card>
  </v-dialog>
</template>
