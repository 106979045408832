<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
</script>

<template>
  <div class="h-full w-full overflow-hidden bg-grey-7">
    <div class="flex h-[100vh] flex-col overflow-auto text-center">
      <img
        src="../../assets/images/bg-no-events.png"
        alt=""
        class="w-[60%] self-center"
      />
      <h1 class="mt-16 text-2xl font-bold text-grey-2">
        {{ $t('events.noEventMessage') }}
      </h1>
      <Button
        id="create-event-button"
        class="mt-4 w-[18rem] self-center py-2"
        @click="
          route.name === 'home-corporative-account'
            ? router.push({
                name: 'create-event',
                params: {
                  id: String(route.params.id),
                },
              })
            : ''
        "
      >
        {{ $t('events.newEvent') }}
      </Button>
    </div>
  </div>
</template>
