import { RouteRecordRaw } from 'vue-router'

export const eventsRoutes: RouteRecordRaw[] = [
  {
    path: '/account/:id/events',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '',
        name: 'events-list',
        component: () => import('@/modules/events/views/List.vue'),
      },
      {
        path: 'new',
        name: 'create-event',
        component: () => import('@/modules/events/views/EventForm.vue'),
      },
      {
        path: ':eventId/edit',
        name: 'edit-event',
        component: () => import('@/modules/events/views/EventForm.vue'),
      },
      {
        path: ':eventId',
        name: 'event-detail',
        component: () => import('@/modules/events/views/Detail.vue'),
      },
      {
        path: 'layout-example',
        name: 'event-layout',
        component: () => import('@/modules/events/views/ExemploPage.vue'),
      },
    ],
  },
]
