<script setup lang="ts">
import { computed } from 'vue'

type AllowedColors = 'success' | 'error' | 'alert' | 'green'

const colorMap: Record<AllowedColors, string> = {
  success: 'bg-success',
  error: 'bg-error',
  alert: 'bg-alert',
  green: 'bg-green',
}

const props = defineProps<{
  color?: AllowedColors
  icon?: string
}>()

const colorClass = computed(() =>
  props.color ? colorMap[props.color] : undefined
)
</script>

<template>
  <v-card
    class="position-relative d-flex flex-column align-center pa-4 justify-center rounded-lg"
  >
    <!-- Ícone com Borda -->
    <v-avatar
      v-if="props.icon"
      class="position-absolute elevation-3 mt-7"
      :class="colorClass"
      size="34"
      style="top: -24px; left: 50%; transform: translateX(-50%)"
    >
      <v-img :src="props.icon" contain width="32" height="32" />
    </v-avatar>

    <!-- Barra Superior Colorida -->
    <div
      v-if="props.color"
      class="w-100 rounded-t-lg"
      :class="colorClass"
      style="height: 8px"
    ></div>

    <!-- Slot para Conteúdo -->
    <slot />
  </v-card>
</template>
