<script setup lang="ts">
import { RouterLink, useRoute } from 'vue-router'

import { useLayout } from '@/composables/useLayout'
import { computed } from 'vue'
import { topNavIcons } from '@/components/Header/top-nav-icons'
const { isSidebarOpen } = useLayout()

import { useCorporativeAccount } from '@/store/corporative'
import { storeToRefs } from 'pinia'
import PermissionsProvider from '@/providers/PermissionsProvider.vue'

const accountStore = useCorporativeAccount()
const route = useRoute()
const { isUsingCorporateAccount } = storeToRefs(accountStore)

const links = computed(() => {
  return [
    // Link para 'Eventos'
    {
      permissions: ['CONTACT_READ'], //deixei a mesma até ter a permission
      target: (route.params.id as string)
        ? {
            name: 'events-list',
            params: { id: route.params.id as string },
          }
        : {},
      label: 'Eventos',
      name: 'events',
      disabled: false,
    },

    // Link para 'Contatos'
    {
      permissions: ['CONTACT_READ'],
      target: (route.params.id as string)
        ? {
            name: 'personal-contacts-list',
            params: { id: route.params.id as string },
          }
        : {},
      label: 'Contatos',
      name: 'contacts',
      disabled: !isUsingCorporateAccount.value, // Desabilita se não for conta corporativa
    },

    // Condicional para adicionar 'Membros' e 'Financeiro' se for uma conta corporativa
    ...(isUsingCorporateAccount.value
      ? [
          {
            permissions: ['MEMBER_READ'],
            target: (route.params.id as string)
              ? {
                  name: 'members-list',
                  params: { id: route.params.id as string },
                }
              : {},
            label: 'Membros',
            name: 'members',
            disabled: false, // Não desabilitado porque é conta corporativa
          },
          {
            target: {},
            label: 'Financeiro',
            name: 'finance',
            disabled: true, // Desabilitado mesmo sendo conta corporativa
          },
        ]
      : []),

    // Link para 'Templates', sempre presente, mas desabilitado
    {
      permissions: ['TEMPLATES_READ'],
      target: {},
      label: 'Templates',
      name: 'templates',
      disabled: true, // Sempre desabilitado
    },
  ].map((link) => {
    const icon =
      topNavIcons[link.name as keyof typeof topNavIcons][
        isUsingCorporateAccount.value ? 0 : 1
      ]
    return { ...link, icon }
  })
})
</script>

<template>
  <nav class="mx-4 flex h-full">
    <PermissionsProvider
      v-for="(link, index) in links"
      :key="index"
      v-slot="{ isAllowed }"
      :permissions="link.permissions || []"
    >
      <RouterLink
        :id="`top-nav-link-${link.name}`"
        :key="index"
        :disabled="!isAllowed || link.disabled"
        :to="link.target"
        class="flex h-full flex-col items-center justify-center gap-1 border-r-[0.1rem] px-8 text-grey-5"
        :class="`${
          isUsingCorporateAccount
            ? 'border-grey-2 text-grey-5'
            : 'border-personal-dark text-white'
        } ${index == 0 && 'border-l-[0.1rem]'}
         ${String(route.name).includes(link.name) ? 'opacity-15' : ''}`"
      >
        <img :src="link.icon" class="w-[1.5rem]" />

        <span v-if="isSidebarOpen" class="text-xs font-bold">{{
          link.label
        }}</span>
      </RouterLink>
    </PermissionsProvider>
  </nav>
</template>

<style scoped></style>
