<script setup lang="ts">
import {
  RuleExpression,
  useField,
  useFormValues,
  useIsFormTouched,
  useSetFieldValue,
} from 'vee-validate'
import { MaybeRef, computed, onMounted, toValue, watch } from 'vue'
import CurrencyInput from '@/components/CurrencyInput/CurrencyInput.vue'
import { CurrencyInputOptions } from 'vue-currency-input'
import Combobox from '@/components/Combobox.vue'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    name: string
    id?: string
    type?: string
    rules?: MaybeRef<RuleExpression<unknown>>
    label?: string
    items?: Array<unknown>
    labelField?: string
    valueField?: string
    disabled?: boolean
    checked?: boolean
    defaultValue?: string
    loading?: boolean
    icon?: string
    leftPosition?: boolean
    onClickButton?: () => void
    decimalPlaces?: number
    hideErrorMessage?: boolean
    error?: string
    placeholder?: string
    emptyState?: boolean
    labelValue?: boolean
    currencyOptions?: CurrencyInputOptions
    min?: number | string
    modelValue?: unknown
    appendInner?: boolean
    prependInner?: boolean
    events?: boolean
  }>(),
  {
    id: '',
    type: 'text',
    labelField: 'name',
    valueField: 'value',
    items: undefined,
    rules: '',
    label: '',
    select: false,
    checked: false,
    defaultValue: undefined,
    icon: '',
    leftPosition: false,
    onClickButton: undefined,
    decimalPlaces: undefined,
    hideErrorMessage: false,
    error: undefined,
    placeholder: undefined,
    emptyState: true,
    labelValue: false,
    currencyOptions: undefined,
    min: undefined,
    modelValue: undefined,
    appendInner: false,
    prependInner: false,
    events: false,
  }
)

const values = useFormValues()
const setFieldValue = useSetFieldValue(props.name)
const isFormTouched = useIsFormTouched()
const { value, errorMessage, setValue, meta, setTouched, handleBlur } =
  useField(
    () => props.name,
    props.type === 'checkbox' ? undefined : props.rules,
    {
      initialValue:
        props.type === 'checkbox' ? false : props.defaultValue || undefined,
      validateOnMount: false,
    }
  )

const label = computed(
  () => `${props.label}${props.label && (meta.required ? ' *' : '')}`
)

const emit = defineEmits([
  'change',
  'country-code',
  'validate',
  'blur',
  'input',
  'update:model-value',
])

const error = computed(() => {
  if (props.error) return props.error
  return isFormTouched.value ? errorMessage : undefined
})

const comboboxQuery = computed({
  get: () => (props.modelValue ? String(props.modelValue) : undefined),
  set: (val) => emit('update:model-value', val),
})

const { type } = props

function handleChange(newValue: string | number | boolean | Event | null) {
  setTouched(true)
  if (props.type === 'currency') {
    emit('change', newValue)
    setValue(newValue)
    return
  }
  if (newValue instanceof Event) {
    const target = newValue.target as HTMLInputElement

    newValue = target.value
  }

  if (typeof newValue === 'string' && newValue.length == 0) newValue = ''
  emit('change', newValue)
  setValue(newValue)
}

onMounted(() => {
  if (type === 'currency' && value.value != values.value) {
    setFieldValue(value.value)
  }
})

watch(value, (newValue) => {
  emit('update:model-value', newValue)
})
</script>
<template>
  <div id="form-input" class="flex w-full flex-col" :class="$attrs.class">
    <Select
      v-if="type === 'select'"
      :id="id"
      v-bind="{ ...$attrs }"
      v-model="value"
      :empty-state="props.emptyState"
      :name="name"
      :disabled="loading || disabled"
      :label="label"
      :placeholder="placeholder"
      :items="props.items"
      :error="errorMessage ? true : false"
      :label-field="props.labelField"
      :value-field="props.valueField"
      @update:model-value="handleChange"
      @input="handleChange"
    />

    <PhoneInput
      v-else-if="type === 'phone'"
      :id="id"
      v-bind="{ ...$attrs }"
      v-model="value"
      :name="name"
      :type="type"
      :label="label"
      :events="events"
      :disabled="loading || disabled"
      :error="errorMessage ? true : false"
      @input="
        (value: string) => {
          setValue(value)
          $emit('change', value)
        }
      "
      @country-code="(value: string) => $emit('country-code', value)"
    />
    <CurrencyInput
      v-else-if="type === 'currency'"
      :id="id"
      v-bind="{ ...$attrs }"
      :value="value"
      :options="currencyOptions"
      :name="name"
      :disabled="loading || disabled"
      :label="label"
      :placeholder="placeholder"
      :min="min as number"
      :error="errorMessage ? true : false"
      @currency="handleChange"
    />
    <Combobox
      v-else-if="type === 'combobox'"
      :id="id"
      v-model="comboboxQuery"
      :label="label"
      :items="items || []"
      :initial-value="defaultValue"
      v-bind="{ ...$attrs, ...$props }"
      :error="Boolean(errorMessage)"
      :value="value"
      @select="
        (value) => {
          const v = $attrs.multiple
            ? (value || [])?.map((v: object) =>
                valueField ? v[valueField as keyof typeof v] : v
              )
            : valueField
              ? value[valueField]
              : value
          handleChange(v)
        }
      "
    />
    <Input
      v-else
      v-bind="{ ...$attrs }"
      :id="id"
      v-model="value"
      :multiline="props.type === 'textarea'"
      :name="name"
      :disabled="loading || disabled"
      :checked="checked"
      :type="type"
      :min="min"
      :label="label"
      :label-value="labelValue"
      :error="toValue(error)"
      :icon="icon"
      :left-position="leftPosition"
      :on-click-button="onClickButton"
      :decimal-places="props.decimalPlaces"
      :append-inner="appendInner"
      :prepend-inner="prependInner"
      :placeholder="placeholder"
      @input="
        (e: Event) => {
          handleChange(e)
          $emit('input', e)
        }
      "
      @blur="
        (e: Event) => {
          handleBlur(e, true)
          $emit('blur')
        }
      "
    />
    <div
      v-if="
        (isFormTouched &&
          type != 'phone' &&
          errorMessage &&
          !hideErrorMessage) ||
        $attrs.maxlength
      "
      class="flex w-full justify-between"
      :class="{ 'opacity-30': disabled || loading }"
    >
      <div class="grow">
        <div
          v-if="
            isFormTouched &&
            type != 'phone' &&
            errorMessage &&
            !hideErrorMessage
          "
          id="error-message"
          class="h-[1rem] pl-1 text-xs text-error"
        >
          {{ $t(errorMessage || '') }}
        </div>
      </div>
      <div v-if="$attrs.maxlength" class="self-end text-xs">
        {{ typeof value === 'string' ? value?.length : 0 }}/{{
          $attrs.maxlength
        }}
      </div>
    </div>
  </div>
</template>
