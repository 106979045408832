<script lang="ts" setup>
import closeIcon from '@/assets/icons/black/close.svg'
import { computed, toRefs } from 'vue'

const props = defineProps<{
  open?: boolean
  id?: string
  title?: string
  text?: string
  modelValue?: boolean
  loading?: boolean
  maxWidth?: string
}>()
const { text, title, id } = toRefs(props)
const emit = defineEmits(['close', 'update:modelValue'])

const isOpen = computed({
  get() {
    return props.open || props.modelValue
  },
  set(v: boolean) {
    emit('update:modelValue', v)
  },
})
</script>

<template>
  <v-dialog :id="id" :model-value="isOpen" persistent max-width="512px">
    <v-card
      :id="id"
      class="pa-4 rounded-lg text-start shadow-lg"
      :style="{
        maxWidth: maxWidth ?? '512px',
        maxHeight: '90vh',
        overflowY: 'auto',
      }"
    >
      <v-card-title class="d-flex justify-space-between align-center">
        <h3 class="text-h5 font-weight-bold text-grey-darken-3">
          <span v-if="title">{{ title }}</span>
          <slot name="title" />
        </h3>
        <v-btn icon @click="$emit('close')">
          <v-img :src="closeIcon" alt="close icon" width="24" height="24" />
        </v-btn>
      </v-card-title>

      <v-card-text>
        <slot></slot>
        <p v-if="text" class="text-body-1">{{ text }}</p>
      </v-card-text>

      <v-card-actions v-if="$slots.actions" class="justify-center">
        <slot v-if="!props.loading" name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
