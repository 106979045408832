<template>
  <div>
    <router-view />
    <AlertDialog
      :is-open="isOpen"
      color="alert"
      class="flex !min-w-full flex-col !px-10 text-sm"
      :text="$t('permissions.blockedAccessDialog')"
      :icon="alertIcon"
      @close="handleClose"
    >
      <div class="my-4 flex gap-5">
        <Button class="min-w-36 bg-alert text-base" @click="handleClose">
          {{ $t('general.ok') }}
        </Button>
      </div>
    </AlertDialog>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { useCorporativeAccount } from '@/store/corporative'
import useStore from '@/store/auth'
import { useLoading } from '@/composables/useLoading'
import { globalPermissionsGuard } from '@/utils/globalPermissionsGuard'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import AlertDialog from '@/components/AlertDialog.vue'
import alertIcon from '@/assets/icons/white/attention.svg'

const corporativeAccountStore = useCorporativeAccount()
const { getCorporates } = corporativeAccountStore

const authStore = useStore()
const { getUserInfo } = authStore
const { user } = storeToRefs(authStore)
const { locale } = useI18n({ useScope: 'global' })
const isOpen = ref(false)
const { isAllowed } = globalPermissionsGuard()

function handleClose() {
  isOpen.value = false
  isAllowed.value = true
}

watch(isAllowed, (newValue) => {
  if (newValue === false) {
    isOpen.value = true
  } else {
    isOpen.value = false
  }
})

onMounted(async () => {
  await getUserInfo()
  locale.value = user.value?.locale

  useLoading(async () => await getCorporates())
})
</script>

<style>
body,
#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.dash:before {
  content: '|';
  color: rgb(--var(v-green));
  color: #8dc63f;
  margin-right: 0.5rem;
}

::-webkit-scrollbar {
  width: 0.44rem;
  height: 0.44rem;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(85, 85, 85, 0.35);
  border-radius: 0.19rem;
}
::-webkit-scrollbar-track {
  background: transparent;
  border-right: 0.06rem solid #c5c5c5;
}

@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(85, 85, 85, 0.35) transparent;
  }
  ::-moz-scrollbar-thumb {
    background-color: rgba(85, 85, 85, 0.35);
    border-radius: 0.19rem;
  }

  ::-moz-scrollbar-track {
    background: transparent;
    border-right: 0.06rem solid #c5c5c5;
  }

  .scroll {
    padding-right: 0.94rem !important;
  }
}

table {
  border-bottom: 0.06rem solid #d8d8d8;
  word-break: break-all;
  border-collapse: inherit !important;
}

.v-pagination__item--is-active {
  background-color: #8dc63f !important;
}

.v-pagination .v-btn:hover {
  background-color: #8dc63f !important;
}

.v-btn:hover > .v-btn__overlay {
  opacity: 0 !important;
}
</style>
