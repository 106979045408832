<script setup lang="ts">
import CountryFlag from 'vue-country-flag-next'
import { CurrencyDisplay, useCurrencyInput } from 'vue-currency-input'
import Input from '@/components/Input.vue'
import { computed, onMounted, watch } from 'vue'
import { useCurrency } from '@/composables/useCurrency'
import { CurrencyValue } from '@/modules/custom-fields/types'

const emit = defineEmits(['currency', 'blur'])

const props = defineProps<{
  value?: unknown
  precision?: number
  error?: boolean
  min?: number
  labelField?: string | undefined
  valueField?: string | undefined
}>()

const { selectedCurrency, currencyList, placeholder } = useCurrency()

const value = computed({
  get: () => props.value,
  set: (newVal: unknown) => {
    if ((newVal as CurrencyValue)?.amount === null)
      return emit('currency', null)
    const t = {
      currency: (newVal as CurrencyValue)?.currency,
      amount:
        (newVal as CurrencyValue)?.amount == '0'
          ? '0.00'
          : String(Number((newVal as CurrencyValue)?.amount)?.toFixed(2)),
    }
    emit('currency', t)
  },
})

const { inputRef, numberValue, setOptions, setValue, formattedValue } =
  useCurrencyInput({
    currency: 'BRL',
    useGrouping: false,
    autoDecimalDigits: true,
    precision: props.precision || 2,
    hideCurrencySymbolOnFocus: false,
    valueRange: {
      min: props.min || undefined,
    },
  })

// ao trocar o tipo de moeda, é necessário mudar a configuração para essa moeda
watch(selectedCurrency, () => {
  setOptions({
    currency: selectedCurrency.value.currencyCode || 'BRL',
    currencyDisplay: 'symbol' as CurrencyDisplay,
    precision: props.precision || 2,
    useGrouping: false,

    autoDecimalDigits: true,
    valueRange: {
      min: props.min || undefined,
    },
  })
  value.value = {
    currency: selectedCurrency.value.currencyCode,
    amount: (value.value as CurrencyValue)?.amount,
  }
})

// reseta caso o valor esteja fora do permitido
watch(
  () => props.min,
  () => {
    if (
      typeof props.min != 'undefined' &&
      numberValue.value &&
      numberValue.value < props.min
    )
      setValue(props.min)
  }
)

// a verificação existe para não setar valor nulo
watch(numberValue, (newVal) => {
  value.value = {
    currency: selectedCurrency.value.currencyCode,
    amount: newVal,
  }
})

onMounted(() => {
  // esse timeout é necessário para que a ref interna da lib seja carregada para depois ser inicializada
  setTimeout(() => {
    if ((props.value as CurrencyValue)?.currency)
      selectedCurrency.value =
        currencyList.find((c) =>
          ((props.value as CurrencyValue)?.currency as string)
            .toLowerCase()
            .includes(c.isoCode.toLowerCase())
        ) || currencyList[0]
    if (
      (props.value as CurrencyValue)?.amount != String(numberValue.value) &&
      typeof (props.value as CurrencyValue)?.amount != 'undefined'
    ) {
      setValue(Number((props.value as CurrencyValue)?.amount))
    }
  }, 500)
})
</script>

<template>
  <div class="flex flex-row">
    <v-select
      v-model="selectedCurrency"
      as="div"
      class="relative ml-2 w-[2.2rem]"
      menu-icon=""
      variant="plain"
      :hide-details="true"
      :items="currencyList"
      :item-title="String(props.labelField)"
      :item-value="String(props.valueField)"
    >
      <template
        id="select-currency"
        #selection
        class="absolute top-[1rem] z-10 ml-0 rounded-l-xl border-r-[1px] border-grey-4 p-2 outline-none peer-focus:border-grey-2 peer-focus:bg-blue-100"
      >
        <div
          class="flex h-[1rem] w-[0.8rem] items-center justify-center overflow-hidden"
        >
          <CountryFlag :country="selectedCurrency?.isoCode" size="small" />
        </div>
      </template>
      <template #item="{ props: innerProps, item }">
        <v-list-item
          v-bind="innerProps"
          :value="item"
          :data-value="item.value.currencyCode"
          class="flex cursor-pointer flex-row items-center gap-2 px-2 py-2 text-sm text-grey-4 hover:bg-grey-4 hover:text-black"
        >
          <div
            class="flex flex-row items-center gap-2 overflow-hidden rounded-md"
          >
            <CountryFlag
              :country="item.value.isoCode"
              size="small"
              class="h-[1.2rem] w-[1.2rem]"
            />
            <p>
              {{ item.value.currencySymbol }} -
              {{ item.value.currencyName }}
            </p>
          </div>
        </v-list-item>
      </template>
    </v-select>
    <Input
      ref="inputRef"
      :model-value="formattedValue ? String(formattedValue) : ''"
      :error="error"
      :placeholder="placeholder"
      @blur="(e: FocusEvent) => e.preventDefault()"
      @keydown="
        (e: KeyboardEvent) => {
          if (e.key === '-' && min === 0) e.preventDefault()
        }
      "
    />
  </div>
</template>
