export const mockEvents = [
  {
    id: 1,
    name: 'Tech Summit 2025: Inovação e Futuro',
    date: '2024-03-01',
    city: 'Curitiba',
    state: 'PR',
    country: 'Brasil',
    format: 'Presencial',
    publication: '--',
    phase: 'Em planejamento',
  },
  {
    id: 2,
    name: 'Fórum de Inteligência Artificial Aplicada',
    date: '2024-04-01',
    city: 'São Paulo',
    state: 'SP',
    country: 'Brasil',
    format: 'Híbrido',
    publication: 'Não publicado',
    phase: 'Em planejamento',
  },
  {
    id: 3,
    name: 'Encontro Nacional de Cibersegurança e Proteção de Dados 2025',
    date: '2024-04-01',
    city: 'São Paulo',
    state: 'SP',
    country: 'Brasil',
    format: 'Online',
    publication: 'Publicado',
    phase: 'Divulgação',
  },
  {
    id: 4,
    name: 'Conferência Global de Startups e Negócios',
    date: '2024-04-01',
    city: 'São Paulo',
    state: 'SP',
    country: 'Brasil',
    format: 'Online',
    publication: 'Pronto',
    phase: 'Finalizado',
  },
  {
    id: 5,
    name: 'Seminário de Transformação Digital e TI',
    date: '2024-07-01',
    city: 'Curitiba',
    state: 'PR',
    country: 'Brasil',
    format: 'Online',
    publication: 'Publicado',
    phase: 'Em andamento',
  },
  {
    id: 6,
    name: 'CIO USA | UTOPIA – Admirável Mundo Novo',
    date: '2024-07-01',
    city: 'São Paulo',
    state: 'SP',
    country: 'Brasil',
    format: 'Híbrido',
    publication: 'Publicado',
    phase: 'Cancelado',
  },
  {
    id: 7,
    name: 'Summit Nacional de Inovação Empresarial',
    date: '2024-08-10',
    city: 'Porto Alegre',
    state: 'RS',
    country: 'Brasil',
    format: 'Presencial',
    publication: 'Publicado',
    phase: 'Divulgação',
  },
  {
    id: 8,
    name: 'Fórum de Tecnologia e Transformação Digital',
    date: '2024-09-05',
    city: 'Belo Horizonte',
    state: 'MG',
    country: 'Brasil',
    format: 'Híbrido',
    publication: 'Não publicado',
    phase: 'Em planejamento',
  },
  {
    id: 9,
    name: 'Conferência Internacional de Segurança Cibernética',
    date: '2024-10-15',
    city: 'Brasília',
    state: 'DF',
    country: 'Brasil',
    format: 'Online',
    publication: 'Pronto',
    phase: 'Finalizado',
  },
  {
    id: 10,
    name: 'Fórum Nacional de Inteligência Artificial e Ética',
    date: '2024-11-20',
    city: 'Recife',
    state: 'PE',
    country: 'Brasil',
    format: 'Presencial',
    publication: 'Publicado',
    phase: 'Divulgação',
  },
  {
    id: 11,
    name: 'Simpósio de Computação Quântica e Futuro Digital',
    date: '2024-12-03',
    city: 'Curitiba',
    state: 'PR',
    country: 'Brasil',
    format: 'Híbrido',
    publication: 'Não publicado',
    phase: 'Em planejamento',
  },
  {
    id: 12,
    name: 'Semana do Empreendedorismo Tecnológico',
    date: '2025-01-18',
    city: 'Florianópolis',
    state: 'SC',
    country: 'Brasil',
    format: 'Online',
    publication: 'Publicado',
    phase: 'Em andamento',
  },
  {
    id: 13,
    name: 'Encontro de Líderes em Transformação Digital',
    date: '2025-02-25',
    city: 'São Luís',
    state: 'MA',
    country: 'Brasil',
    format: 'Presencial',
    publication: 'Publicado',
    phase: 'Finalizado',
  },
  {
    id: 14,
    name: 'CyberSec Brasil 2025 - Cibersegurança e Compliance',
    date: '2025-03-12',
    city: 'Belém',
    state: 'PA',
    country: 'Brasil',
    format: 'Híbrido',
    publication: 'Publicado',
    phase: 'Divulgação',
  },
  {
    id: 15,
    name: 'Fórum de Inovação e Gestão Pública Digital',
    date: '2025-04-05',
    city: 'Goiânia',
    state: 'GO',
    country: 'Brasil',
    format: 'Online',
    publication: 'Pronto',
    phase: 'Finalizado',
  },
  {
    id: 16,
    name: 'Tech Leaders Summit 2025',
    date: '2025-05-20',
    city: 'Campo Grande',
    state: 'MS',
    country: 'Brasil',
    format: 'Presencial',
    publication: 'Publicado',
    phase: 'Em andamento',
  },
  {
    id: 17,
    name: 'Semana de Inteligência Artificial e Automação',
    date: '2025-06-15',
    city: 'Fortaleza',
    state: 'CE',
    country: 'Brasil',
    format: 'Híbrido',
    publication: 'Não publicado',
    phase: 'Em planejamento',
  },
  {
    id: 18,
    name: 'Conferência Nacional de Cloud Computing',
    date: '2025-07-08',
    city: 'João Pessoa',
    state: 'PB',
    country: 'Brasil',
    format: 'Online',
    publication: 'Publicado',
    phase: 'Divulgação',
  },
  {
    id: 19,
    name: 'Fórum de Cibersegurança e Compliance',
    date: '2025-08-22',
    city: 'Aracaju',
    state: 'SE',
    country: 'Brasil',
    format: 'Presencial',
    publication: 'Rascunho',
    phase: 'Em planejamento',
  },
  {
    id: 20,
    name: '4ConNetworker Futuro Digital | IA & Blockchain',
    date: '2025-09-15',
    city: 'Florianópolis',
    state: 'SC',
    country: 'Brasil',
    format: 'Híbrido',
    publication: 'Publicado',
    phase: 'Divulgação',
  },
]
