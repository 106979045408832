<script setup lang="ts">
import { VBtn, VIcon, VChip, VDataTableServer } from 'vuetify/components'
import { ref, computed, withDefaults, defineEmits, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import noItens from '@/assets/images/noItens.png'

const emit = defineEmits(['delete-item', 'update-item', 'update'])

const props = withDefaults(
  defineProps<{
    columns?: Array<{
      title: string
      key: string
      sortable: boolean
      width?: string
    }>
    items?: Array<Record<string, unknown>>
    viewActions?: boolean
    hasNextPage?: boolean
    itemsLength?: number
    currentPage?: number
    itemsPerPage?: number
  }>(),
  {
    columns: () => [],
    items: () => [],
    viewActions: false,
    itemsLength: 0,
    currentPage: 1,
    itemsPerPage: 30,
  }
)

const { t } = useI18n()

const search = ref('')
const confirmDeleteDialog = ref(false)
const itemToDelete = ref<Record<string, unknown> | null>(null)

const confirmDelete = () => {
  if (itemToDelete.value) {
    emit('delete-item', itemToDelete.value?.id)
    confirmDeleteDialog.value = false
  }
}

const filteredItems = computed(() => {
  if (!search.value) return props.items
  return props.items.filter((item) =>
    Object.values(item).some((value) =>
      String(value).toLowerCase().includes(search.value.toLowerCase())
    )
  )
})

const formattedHeaders = computed(() => {
  return props.columns.map((column) => ({
    title: column.title,
    key: column.key,
    sortable: column.sortable,
    width: column.width || '150px',
    class: 'text-black px-4 whitespace-nowrap overflow-hidden text-ellipsis',
  }))
})

const formatDate = (dateString: string | null | undefined) => {
  if (!dateString || dateString === 'null') return ''

  try {
    const date = new Date(dateString)
    if (isNaN(date.getTime())) {
      console.warn('Data inválida:', dateString)
      return ''
    }
    return new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      timeZone: 'UTC',
    }).format(date)
  } catch (error) {
    console.error('Erro ao formatar data:', dateString, error)
    return ''
  }
}

const stageChipColors: Record<string, { background: string; color: string }> = {
  PLANNING: { background: '#e3e7b9', color: '#827718' },
  PROMOTION: { background: '#b4e4fd', color: '#01569b' },
  DONE: { background: '#DCEDC8', color: '#38761d' },
  IN_PROGRESS: { background: '#b2ebf2', color: '#016164' },
  CANCELED: { background: '#ffcdd2', color: '#b71d1c' },
}
const currentPageLocal = ref(props.currentPage)
const itemsPerPageLocal = ref(props.itemsPerPage)

watch(
  () => props.currentPage,
  (val) => {
    currentPageLocal.value = val
  }
)

watch(
  () => props.itemsPerPage,
  (val) => {
    itemsPerPageLocal.value = val
  }
)

watch(
  () => props.currentPage,
  (newPage) => {
    currentPageLocal.value = newPage
  }
)
</script>

<template>
  <div class="flex min-h-[620px] flex-col justify-between">
    <v-data-table-server
      :headers="formattedHeaders"
      :items="filteredItems"
      :items-per-page="itemsPerPage"
      :items-length="props.itemsLength"
      :page="props.currentPage"
      class="data-table m-0 flex-grow"
      :items-per-page-text="t('general.itemsPerPage')"
      :style="{ width: '100%', minWidth: '960px', maxWidth: '1504px' }"
      :hide-default-footer="true"
      @update:options="
        ({ page, itemsPerPage }) => {
          if (
            page !== props.currentPage ||
            itemsPerPage !== props.itemsPerPage
          ) {
            emit('update', {
              page: page,
              itemsPerPage,
            })
          }
        }
      "
      @click:row="
        (_: MouseEvent, data: any) => emit('update-item', data.item.id)
      "
    >
      <!-- Coluna: Nome -->
      <template #item.name="{ item }">
        <span class="text-body-2" style="opacity: 0.8">
          {{ item.name }}
        </span>
      </template>

      <!-- Coluna: Data de Início -->
      <template #[`item.startDate`]="{ item }">
        <span class="text-body-2" style="opacity: 0.8">
          {{ formatDate(String(item.startDate)) || '--' }}
        </span>
      </template>

      <!-- Coluna: Data de Término -->
      <template #[`item.endDate`]="{ item }">
        <span class="text-body-2" style="opacity: 0.8">
          {{ formatDate(String(item.endDate)) || '--' }}
        </span>
      </template>

      <template #header.startDate="{ column }">
        <span class="ml-1">{{ column.title }}</span>
      </template>

      <template #header.endDate="{ column }">
        <span class="ml-1">{{ column.title }}</span>
      </template>

      <!-- Coluna: Formato -->
      <template #[`item.format`]="{ item }">
        <span class="text-body-2" style="opacity: 0.8">
          {{
            item.format && item.format !== '-'
              ? t(`events.type.${String(item.format).toLowerCase()}`)
              : '--'
          }}
        </span>
      </template>

      <!-- Coluna: Status -->
      <template #[`item.status`]="{ item }">
        <span class="text-body-2" style="opacity: 0.8">
          {{ t(`events.status.${String(item.status).toLowerCase()}`) || '--' }}
        </span>
      </template>

      <!-- Coluna: Localização -->
      <template #[`item.formattedLocation`]="{ item }">
        <span class="text-body-2" style="opacity: 0.8">
          {{ item.formattedLocation || '--' }}
        </span>
      </template>

      <!-- Coluna: Etapa -->
      <template #[`item.stage`]="{ item }">
        <v-chip
          size="small"
          :style="{
            backgroundColor:
              stageChipColors[String(item.stage).toUpperCase()]?.background ||
              'grey',
            color:
              stageChipColors[String(item.stage).toUpperCase()]?.color ||
              'black',
          }"
        >
          {{ t(`events.stage.${String(item.stage).toLowerCase()}`) }}
        </v-chip>
      </template>

      <!-- Coluna: Local -->
      <template #[`item.venue`]="{ item }">
        <span class="text-body-2" style="opacity: 0.8">
          <v-icon size="small">mdi-map-marker</v-icon>
          <span>
            {{ item.venue || t('general.notAvailable') }}
          </span>
        </span>
      </template>

      <template #no-data>
        <div
          class="flex w-full items-center justify-center"
          style="height: 500px"
        >
          <div
            class="flex w-full max-w-xl flex-col items-center px-6 text-center"
            style="word-break: break-word; overflow-wrap: break-word"
          >
            <img :src="noItens" alt="Empty illustration" />
            <h3 class="mb-2 text-xl font-semibold text-gray-800">
              {{ t('events.empty.title') }}
            </h3>
            <p
              class="mb-4 text-sm leading-relaxed text-gray-600"
              style="word-break: break-word; overflow-wrap: break-word"
            >
              {{ t('events.empty.description') }}
            </p>
          </div>
        </div>
      </template>
    </v-data-table-server>

    <div
      class="flex w-full items-center justify-center gap-4 px-4 py-2 text-sm text-gray-600"
    >
      <!-- Botões de paginação -->

      <v-pagination
        v-if="items.length > 0"
        v-model="currentPageLocal"
        :total-visible="3"
        :show-first-last-page="true"
        :length="
          Math.ceil(
            Number(props.itemsLength || 0) / Number(props.itemsPerPage || 30)
          )
        "
        class="custom-pagination"
        @update:model-value="
          emit('update', { page: $event, itemsPerPage: props.itemsPerPage })
        "
      />
    </div>

    <!-- Diálogo de confirmação de exclusão -->
    <v-dialog v-model="confirmDeleteDialog" max-width="400px">
      <v-card>
        <v-card-title>{{ t('general.confirmDeletion') }}</v-card-title>
        <v-card-text>{{ t('general.confirmDeleteMessage') }}</v-card-text>
        <v-card-actions>
          <v-btn color="grey" @click="confirmDeleteDialog = false">{{
            t('general.cancel')
          }}</v-btn>
          <v-btn color="red" @click="confirmDelete">{{
            t('general.delete')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
/* Tabela sem bordas verticais */
.no-vertical-borders ::v-deep(.v-table__wrapper table) {
  border-collapse: collapse;
}

.no-vertical-borders ::v-deep(.v-table__wrapper table tr td),
.no-vertical-borders ::v-deep(.v-table__wrapper table tr th) {
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.no-vertical-borders ::v-deep(.v-table__wrapper table tr td:first-child),
.no-vertical-borders ::v-deep(.v-table__wrapper table tr th:first-child) {
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.no-vertical-borders ::v-deep(.v-table__wrapper table tr td:last-child),
.no-vertical-borders ::v-deep(.v-table__wrapper table tr th:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
}

/* Cor do texto nas células */
::v-deep(.v-data-table tbody tr td) {
  color: rgba(0, 0, 0, 0.8);
}

/* Container centralizado */
.table-wrapper {
  max-width: 1504px;
  width: 100%;
  margin: 0 auto;
}

/* Estilo dos cabeçalhos e células para evitar quebra */
::v-deep(.v-data-table thead th),
::v-deep(.v-data-table thead th .v-data-table-header__content),
::v-deep(.v-data-table-header th),
::v-deep(.v-data-table-header th .v-data-table-header__content),
::v-deep(.v-data-table tbody td) {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

/* Estilo extra para header content */
::v-deep(.v-data-table thead th .v-data-table-header__content) {
  display: block;
  max-width: 100%;
}

/* Evita quebra nas células também */
::v-deep(.v-data-table tbody td) {
  max-width: 0;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep(.custom-pagination .v-pagination__prev),
::v-deep(.custom-pagination .v-pagination__next),
::v-deep(.custom-pagination .v-pagination__first),
::v-deep(.custom-pagination .v-pagination__last) {
  border-radius: 8px !important;
  min-width: 40px !important;
  height: 40px !important;
  width: 40px !important;
  font-size: 12px;
  background-color: white !important;
  color: black !important;
  box-shadow:
    0px 2px 4px rgba(0, 0, 0, 0.1),
    0px 2px 8px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.2s;
}

::v-deep(.custom-pagination .v-pagination__more) {
  min-width: 40px !important;
  height: 40ch !important;
  width: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.6);
}

::v-deep(.custom-pagination .v-pagination__item--is-active) {
  background-color: #8bc34a !important;
  color: white !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

::v-deep(.custom-pagination .v-pagination__item) {
  border-radius: 8px !important;
  min-width: 40px !important;
  height: 40px !important;
  width: 40px !important;
  font-size: 12px;
  font-weight: 500;
  background-color: white !important;
  color: black !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow:
    0px 2px 4px rgba(0, 0, 0, 0.1),
    0px 2px 8px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.2s;
}
</style>
