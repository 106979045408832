<script setup lang="ts">
import PillTag from '@/modules/contacts/components/PillTag.vue'
import BackButton from '@/components/BackButton.vue'
import { RouteLocationRaw } from 'vue-router'

const props = defineProps<{
  title: string
  backButton?: boolean
  text?: string
  image?: string
  color?: boolean
  type?: 'personal' | 'corporative'
  backButtonTo?: RouteLocationRaw
}>()
</script>

<template>
  <v-row class="py-2 pr-4" align="center" no-gutters>
    <!-- Botão de voltar -->
    <v-col v-if="props.backButton" cols="auto" class="me-4">
      <BackButton :to="props.backButtonTo" />
    </v-col>

    <!-- Imagem -->
    <v-col v-if="props.image" cols="auto" class="me-4">
      <v-img
        :src="props.image"
        alt="contact-image"
        width="48"
        height="48"
        :class="props.type === 'personal' ? 'rounded-circle' : ''"
        cover
      />
    </v-col>

    <!-- Barra lateral verde -->
    <v-col v-if="props.image" cols="auto" class="me-5">
      <PillTag class="h-9 w-1 bg-success" />
    </v-col>

    <!-- Título e texto -->
    <v-col>
      <div class="d-flex flex-column justify-start">
        <h2
          class="text-h5 font-weight-bold text-truncate"
          :class="props.color ? 'text-success' : 'text-grey-darken-1'"
          style="max-width: 90%"
        >
          {{ props.title }}
        </h2>
        <p class="text-body-2 font-weight-bold text-grey">
          {{ props.text }}
        </p>
      </div>
    </v-col>
  </v-row>
</template>
