<script setup lang="ts">
import { computed } from 'vue'

type AllowedColors = 'success' | 'error' | 'alert' | 'green'

const { color, icon, title } = defineProps<{
  color?: AllowedColors
  icon?: string
  title: string
}>()

const colorClass = computed(() => {
  if (!color) return
  const colorMap = {
    success: 'bg-success',
    error: 'bg-error',
    alert: 'bg-warning',
    green: 'bg-green',
  }
  return colorMap[color as AllowedColors]
})
</script>

<template>
  <div
    class="position-relative d-flex flex-column pa-4 justify-center rounded-lg bg-white"
    v-bind="$attrs"
  >
    <!-- Barra de cor ao lado -->
    <div
      v-if="color"
      :class="[
        colorClass,
        'position-absolute h-100 rounded-start-lg start-0 top-0 w-2',
      ]"
    ></div>

    <!-- Ícone e título -->
    <div v-if="icon" class="d-flex align-center w-100 flex-row ps-2">
      <div
        v-if="color"
        class="d-flex align-center rounded-circle justify-center"
        style="height: 32px; width: 32px"
      >
        <img :src="icon" style="height: 24px; width: 24px" />
      </div>

      <div class="flex-grow-1 ps-4 text-start">
        <h3 class="text-h6 font-weight-bold py-2">{{ title }}</h3>
      </div>
    </div>

    <!-- Slot -->
    <div class="w-100 ps-16 text-start">
      <slot />
    </div>
  </div>
</template>
