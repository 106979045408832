<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{
  title: string
  startDate: string
  endDate: string
  location: string
  format: string
  status: string
  stage: string
}>()

// Estilos para o status (publicado, rascunho, etc.)
const statusLabel = computed(() => {
  return t(`events.status.${String(props.status).toLowerCase()}`)
})

const stageChipColors: Record<string, { background: string; color: string }> = {
  PLANNING: { background: '#e3e7b9', color: '#827718' },
  PROMOTION: { background: '#b4e4fd', color: '#01569b' },
  DONE: { background: '#DCEDC8', color: '#38761d' },
  IN_PROGRESS: { background: '#b2ebf2', color: '#016164' },
  CANCELED: { background: '#ffcdd2', color: '#b71d1c' },
}

// 🔹 Pega a cor correta baseada no estágio (com fallback)
const stageStyle = computed(() => {
  const stageKey = String(props.stage).toUpperCase()
  return (
    stageChipColors[stageKey] || { background: '#e0e0e0', color: '#000000' }
  )
})

// 🔹 Traduz o texto do estágio, igual ao `Table.vue`
const translatedStage = computed(() => {
  return t(`events.stage.${String(props.stage).toLowerCase()}`)
})
</script>

<template>
  <v-card class="pa-4 elevation-2 mb-4 rounded-lg shadow-sm">
    <div class="d-flex justify-space-between align-center">
      <span class="text-body-2 text-grey-darken-1 pl-4">{{ statusLabel }}</span>
      <v-chip size="small" :style="stageStyle">
        {{ translatedStage }}
      </v-chip>
    </div>

    <v-card-title class="text-h6 font-weight-bold text-truncate">
      {{ title }}
    </v-card-title>

    <v-card-subtitle class="d-flex align-center text-grey-darken-1">
      {{ startDate }} a {{ endDate }} - {{ format }}
    </v-card-subtitle>

    <v-card-text class="d-flex align-center text-grey-darken-1">
      <v-icon size="small" class="mr-1">mdi-map-marker</v-icon>
      {{ location }}
    </v-card-text>
  </v-card>
</template>

<style scoped>
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
