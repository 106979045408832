<script setup lang="ts">
import { ref, defineProps, computed } from 'vue'

const props = defineProps<{
  headerText?: string
  bodyText?: string[]
  footerText?: string
  position?: string
}>()
const isHovered = ref(false)

const showTooltip = (event: Event) => {
  triggerRect.value = (event.target as HTMLDivElement).getBoundingClientRect()
  isHovered.value = true
}
const hideTooltip = () => {
  triggerRect.value = undefined
  isHovered.value = false
}
const hasText = computed(() =>
  Boolean(props.headerText || props.bodyText || props.footerText)
)

const triggerRect = ref<DOMRect>()

const isOnRightSide = computed(
  () => Number(triggerRect.value?.x || 0) / document.body.clientWidth > 0.5
)

const isOnBottom = computed(
  () => Number(triggerRect.value?.y || 0) / document.body.clientHeight > 0.5
)

const computedStyles = computed(() =>
  triggerRect.value
    ? {
        bottom: isOnBottom.value
          ? `${document.body.clientHeight - triggerRect.value.top}px`
          : '',
        top: isOnBottom.value ? '' : `${triggerRect.value.bottom}px`,
        left: isOnRightSide.value ? '' : `${triggerRect.value.left}px`,
        right: isOnRightSide.value
          ? `${document.body.clientWidth - triggerRect.value.right}px`
          : '',
      }
    : {}
)
</script>

<template>
  <div
    class=""
    :class="{ 'cursor-pointer': hasText }"
    @mouseover="showTooltip"
    @mouseleave="hideTooltip"
  >
    <slot></slot>
    <div
      v-if="isHovered && hasText"
      class="fixed !z-50 w-max max-w-[300px] rounded bg-grey-1 p-2 text-sm text-grey-5"
      :style="computedStyles"
    >
      <div
        v-if="props.headerText"
        class="mb-2 !font-normal"
        v-html="props.headerText"
      ></div>
      <h3
        v-for="(text, index) in props.bodyText"
        :key="index"
        class="dash mb-3 mr-24 truncate font-bold"
      >
        {{ text }}
      </h3>
      <h1 v-if="props.footerText" class="mb-2">
        {{ props.footerText }}
      </h1>
    </div>
  </div>
</template>
