<script setup lang="ts">
import { defineProps, computed } from 'vue'

// Define as props do componente com valores padrão
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    default: 'mdi-chevron-down',
  },
  startDate: {
    type: String,
    default: '', // 🔹 Agora não gera o warning
  },
  endDate: {
    type: String,
    default: '', // 🔹 Agora não gera o warning
  },
})

// Função para formatar datas corretamente sem a vírgula
const formatDate = (dateString: string) => {
  if (!dateString) return 'Data não informada'

  const date = new Date(dateString)

  const formattedDate = new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(date)

  const formattedTime = new Intl.DateTimeFormat('pt-BR', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(date)

  return `${formattedDate} ${formattedTime}` // 🔹 Junta sem a vírgula
}

// Computed properties para exibir as datas formatadas
const formattedStartDate = computed(() => formatDate(props.startDate))
const formattedEndDate = computed(() => formatDate(props.endDate))
</script>

<template>
  <v-expansion-panels class="expandable-card">
    <v-expansion-panel>
      <v-expansion-panel-title>
        <v-icon v-if="icon" class="mr-2">{{ icon }}</v-icon>
        {{ title }}
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <slot />

        <p v-if="startDate">
          <strong>Início:</strong> {{ formattedStartDate }}
        </p>
        <p v-if="endDate"><strong>Fim:</strong> {{ formattedEndDate }}</p>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<style scoped>
.expandable-card {
  max-width: 100%;
}
</style>
