<script setup lang="ts" generic="T">
import greenInfoIcon from '@/assets/icons/green/info.svg'
import { computed, toRefs, ref } from 'vue'
import { TransitionRoot } from '@headlessui/vue'
import { useMenuPosition } from '@/composables/useMenuPosition'
import { useI18n } from 'vue-i18n'
import { MessageSchema } from '@/plugins/i18n'

const { t } = useI18n<MessageSchema>()
defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    label: string
    id?: string
    required?: boolean
    items: unknown[]
    modelValue?: string | number | undefined
    labelField?: keyof T | string
    valueField?: keyof T | string
    loading?: boolean
    error?: boolean
    emptyState?: boolean
    placeholder?: string
    disabled?: boolean
    tooltip?: string
    description?: string
  }>(),
  {
    id: '',
    required: false,
    labelField: 'label',
    valueField: 'value',
    modelValue: undefined,
    loading: false,
    error: false,
    emptyState: true,
    placeholder: undefined,
    disabled: false,
    tooltip: undefined,
    description: undefined,
  }
)

const emit = defineEmits(['update:modelValue'])
const { required, items } = toRefs(props)

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const listboxRef = ref<HTMLElement | null>(null)

const { triggerRect, computedStyles, toggleMenu } = useMenuPosition()

const getItemValue = (item: T): string | number | undefined => {
  return item[props.valueField as keyof T] as string | number | undefined
}

const selectedItemLabel = computed(() => {
  const selectedItem = items.value?.find(
    (item: T) => getItemValue(item) === value.value
  )
  return selectedItem
    ? selectedItem[props.labelField as keyof T]
    : props.placeholder || t('general.pickAnOption')
})
</script>

<template>
  <div class="flex min-w-32 flex-col pt-1">
    <div :id="id" ref="listboxRef" class="relative mt-2 flex">
      <v-select
        v-model="value"
        variant="outlined"
        density="comfortable"
        rounded="lg"
        :hide-details="!description"
        :hint="description"
        :persistent-hint="!!description"
        :disabled="props.loading || props.disabled"
        :placeholder="placeholder"
        :items="items"
        :item-title="String(props.labelField)"
        :item-value="String(props.valueField)"
        class="bg-white"
      >
        <template #label>
          <h6
            v-if="props.label"
            class="mb-2 text-sm font-bold text-grey-2"
            :class="{
              '!text-error': props.error,
              '!text-grey-4': !props.error && props.disabled,
              'w-11/12': tooltip,
            }"
          >
            {{ props.label }} {{ required ? '*' : '' }}
          </h6>
        </template>
        <template #selection>
          {{ selectedItemLabel }}
        </template>
        <template v-if="tooltip" #append>
          <img
            id="tooltip"
            width="24"
            :src="greenInfoIcon"
            @mouseover="toggleMenu"
            @mouseout="toggleMenu"
          />
          <Teleport to="body">
            <TransitionRoot
              :show="Boolean(triggerRect)"
              enter="transition-opacity duration-150"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="transition-opacity duration-150"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div
                id="tooltip-text"
                :style="computedStyles"
                class="absolute z-50 mt-2 h-max w-max max-w-[30rem] origin-top-left divide-y break-words rounded-md bg-grey-1 p-2 font-light text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                {{ tooltip }}
              </div>
            </TransitionRoot>
          </Teleport>
        </template>
      </v-select>
    </div>
  </div>
</template>
<style>
.v-list-item:hover {
  background-color: #8dc63f !important;
  color: white !important;
}
</style>
