<script lang="ts" setup>
const props = defineProps<{
  customClass?: string
}>()
</script>

<template>
  <span
    class="mx-1 flex w-fit items-center justify-center px-1"
    :class="props.customClass"
  >
    <slot />
  </span>
</template>
