import { z } from 'zod'
import { PhoneNumberUtil } from 'google-libphonenumber'
const phoneUtil = PhoneNumberUtil.getInstance()

export const phoneFormSchema = z
  .object({
    number: z.string(),
    countryCode: z.string(),
    label: z.string().optional(),
    main: z.boolean().optional(),
    id: z.number().optional(),
    persons: z.array(z.string()).optional(),
    whatsapp: z.boolean().optional(),
  })
  .partial()
  .refine((schema) => {
    if (!schema?.number) {
      return false
    }
    if (!schema.countryCode) return false
    try {
      return phoneUtil.isValidNumberForRegion(
        phoneUtil.parse(schema.number, schema.countryCode),
        schema.countryCode
      )
    } catch {
      return false
    }
  }, 'validation.phone')

export type Phone = z.infer<typeof phoneFormSchema>
