<script setup lang="ts">
import wallet from '@/assets/icons/black/company.svg'
import editIcon from '@/assets/icons/black/edit.svg'
import checkOutRedIcon from '@/assets/icons/check_out_red.svg'
import { MenuItem, MenuItems } from '@headlessui/vue'
import { useI18n } from 'vue-i18n'
import CardOptions from '@/components/CardOptions.vue'

const { t } = useI18n()

const { id } = defineProps<{ id: number; isOwner: boolean }>()
</script>

<template>
  <CardOptions :id="`corporative-account-menu`">
    <MenuItems
      as="div"
      class="position-absolute z-index-10 w-75 focus-outline-none end-0 top-0 mt-0 rounded-md border bg-white shadow-lg ring-1 ring-black ring-opacity-5"
    >
      <Card class="d-flex w-100 align-start pa-0" color="green">
        <div class="d-flex w-100 flex-column flex-md-row mt-1">
          <div class="flex-grow-1 d-flex align-center mt-2 w-10">
            <img :src="wallet" alt="" class="me-2 h-10 w-10" />
          </div>
          <div class="w-100 flex-grow-1 d-flex align-center m-2 pt-2">
            <RouterLink
              :to="{ name: 'detail-corporative-account', params: { id } }"
              class="d-flex align-center"
            >
              <span class="font-weight-bold mt-0">{{
                t('profile.corporates.goToCorporativeAccount')
              }}</span>
            </RouterLink>
          </div>
        </div>
        <div v-if="$props.isOwner" class="d-flex w-100 align-center mx-2 my-1">
          <div
            class="flex-grow-1 border-top border-bottom border-grey-lighten-2"
          ></div>
        </div>
        <div
          v-if="$props.isOwner"
          class="d-flex w-100 flex-column flex-md-row mb-2 mt-0"
        >
          <div class="flex-grow-1 d-flex align-center ms-2 mt-2 w-10">
            <img :src="editIcon" alt="" class="me-2" />
          </div>
          <div class="w-100 flex-grow-1 d-flex align-center ms-2 mt-2 pt-0">
            <RouterLink
              :to="{ name: 'edit-corporative-account', params: { id: id } }"
              class="d-flex align-center font-weight-bold"
            >
              <span class="mt-0">{{
                t('profile.corporates.editCorporativeAccount')
              }}</span>
            </RouterLink>
          </div>
        </div>
        <div class="d-flex w-100 align-center mx-2 my-1">
          <div
            class="flex-grow-1 border-top border-bottom border-grey-lighten-2"
          ></div>
        </div>
        <div class="d-flex w-100 flex-column flex-md-row mb-2 mt-1">
          <div class="flex-grow-1 d-flex align-center ms-2 mt-2 w-10">
            <img :src="checkOutRedIcon" alt="" class="me-2" />
          </div>
          <div class="w-100 flex-grow-1 d-flex align-center m-2 pt-0">
            <MenuItem as="div" class="w-100 text-start text-error">
              <div class="d-flex font-weight-bold">
                <span>{{ t('profile.corporates.exit') }}</span>
              </div>
            </MenuItem>
          </div>
        </div>
      </Card>
    </MenuItems>
  </CardOptions>
</template>
