<script lang="ts" setup>
import { computed, ref, useAttrs } from 'vue'
import 'vue-tel-input/vue-tel-input.css'
import { PhoneNumberUtil } from 'google-libphonenumber'
const phoneUtil = PhoneNumberUtil.getInstance()
const props = withDefaults(
  defineProps<{
    modelValue?: string | number | boolean | Date
    error?: boolean
    label?: string
    required?: false
    events?: false
    id?: string
  }>(),
  {
    required: false,
    label: '',
    modelValue: '',
    error: false,
    id: '',
  }
)

const emit = defineEmits([
  'update:modelValue',
  'input',
  'country-code',
  'validate',
])

defineOptions({ inheritAttrs: false })
const attrs = useAttrs()
const { label, required } = props

type PhoneNumber = {
  country: { dialCode: string }
  countryCallingCode: string
  countryCode: string
  number: string
  valid: boolean
  formatted: string
  nationalNumber: string
}

const isPhoneValid = ref(true)
function handleInput(_number: string, phoneObject: PhoneNumber) {
  if (phoneObject.number) emit('input', phoneObject.number)
  else if (phoneObject.formatted) emit('input', phoneObject.formatted)
  emit('country-code', phoneObject.countryCode)

  if (!_number) return (isPhoneValid.value = true)
  if (!/^[0-9\s+]+$/.test(_number)) {
    isPhoneValid.value = false
    return
  }
  if (!phoneObject.countryCode) return (isPhoneValid.value = true)
  try {
    isPhoneValid.value = phoneUtil.isValidNumberForRegion(
      phoneUtil.parse(_number, phoneObject.countryCode),
      phoneObject.countryCode
    )
  } catch {
    isPhoneValid.value = false
  }
}

const phoneNumber = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
})
</script>

<template>
  <h6
    v-if="label"
    :class="`${!isPhoneValid ? 'text-error' : ''} ${!events ? 'my-2' : 'ml-8 mt-2 text-grey-3'} text-sm font-bold `"
  >
    {{ label }} {{ required ? '*' : '' }}
  </h6>
  <vue-tel-input
    v-bind="{ ...attrs }"
    :id="id"
    v-model="phoneNumber"
    :input-options="{ placeholder: '', showDialCode: true }"
    :auto-format="true"
    placeholder="''"
    default-country="BR"
    mode="international"
    :class="{ error: !isPhoneValid || props.error }"
    @on-input="handleInput"
  />
  <div
    v-if="!isPhoneValid && !error"
    id="error-message"
    class="h-[1rem] py-1 pl-14 text-xs text-error"
  >
    {{ !isPhoneValid && !error ? $t('validation.phone') : undefined }}
  </div>
</template>

<style>
.vue-tel-input {
  border: 1px solid;
  border-radius: 0.5rem !important;
  border-color: rgb(187 187 187) !important;
  background-color: white !important;
}

.vue-tel-input:focus-within {
  box-shadow: none !important;
  border-color: rgb(74 74 74) !important;
}

.vue-tel-input input {
  border-left: 1px solid !important;
  border-left-color: rgb(187 187 187) !important;
  border-radius: 0 0.5rem 0.5rem 0 !important;
}

.vue-tel-input:focus-within input,
.vue-tel-input:focus-within .vti__dropdown {
  border-color: rgb(74 74 74) !important;
}

.vti__input {
  background-color: white;
  height: 48px;
}

.vti__dropdown-item .vti__flag {
  display: none;
}
.vti__dropdown-item strong {
  font-weight: 400 !important;
}
</style>
