<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import orderIconDown from '@/assets/icons/green/order_down.svg'
import orderIconUp from '@/assets/icons/green/order_up.svg'
import orderIconGrey from '@/assets/icons/grey/order.svg'
import arrowIcon from '@/assets/icons/grey/arrow_down.svg'

const props = defineProps<{
  modelValue: string | null
  order: string | null
  orderingOptions: { value: string; label: string }[]
  isSecondary?: boolean
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | null): void
  (e: 'update-order', value: string): void
}>()

const localValue = ref(props.modelValue)

watch(localValue, (newValue) => {
  emit('update:modelValue', newValue)
})

const toggleOrder = () => {
  emit('update-order', props.order === 'asc' ? 'desc' : 'asc')
}

const orderIcon = computed(() => {
  if (props.order === 'asc') return orderIconDown
  if (props.order === 'desc') return orderIconUp
  return orderIconGrey
})
</script>

<template>
  <div class="mb-4">
    <template v-if="isSecondary">
      <v-divider class="my-5" />
      <h3 class="text-subtitle-1 font-weight-bold pb-3">
        {{ $t('corporate.order.modalSecondLabel') }}
      </h3>
    </template>

    <v-row align="center" no-gutters>
      <v-col cols="12" sm="9">
        <v-select
          v-model="localValue"
          :items="orderingOptions"
          item-title="label"
          item-value="label"
          :placeholder="$t('corporate.order.title')"
          density="comfortable"
          variant="outlined"
          clearable
          hide-details
        >
          <template #append-inner>
            <v-img :src="arrowIcon" width="20" height="20" class="me-2" />
          </template>
        </v-select>
      </v-col>

      <v-col
        cols="12"
        sm="3"
        class="d-flex align-center mt-sm-0 mt-2 justify-end"
      >
        <v-img
          id="order-button"
          :src="orderIcon"
          width="32"
          height="32"
          class="rounded-circle border-0"
          @click="toggleOrder"
        />
      </v-col>
    </v-row>
  </div>
</template>
