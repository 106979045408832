<script setup lang="ts">
import greenInfoIcon from '@/assets/icons/green/info.svg'
import { useMenuPosition } from '@/composables/useMenuPosition'
import { TransitionRoot } from '@headlessui/vue'

import { computed, ref, toRefs, useAttrs } from 'vue'

const props = withDefaults(
  defineProps<{
    label?: string
    required?: boolean
    modelValue?: string | number | boolean | Date
    multiline?: boolean
    error?: boolean
    disabled?: boolean
    checked?: boolean | undefined
    type?: string
    inputType?: string
    icon?: string
    iconPosition?: 'start' | 'end'
    onClickButton?: () => void
    comments?: boolean
    description?: string
    tooltip?: string
    decimalPlaces?: number
    placeholder?: string
    labelValue?: boolean
    inputClasses?: string
    flag?: boolean
    appendInner?: boolean
    prependInner?: boolean
    append?: boolean
    min?: string
    labelField?: string | undefined
    valueField?: string | undefined
  }>(),
  {
    label: '',
    required: false,
    type: 'text',
    inputType: 'text',
    modelValue: '',
    multiline: false,
    error: false,
    disabled: false,
    checked: false,
    icon: '',
    iconPosition: 'end',
    onClickButton: undefined,
    comments: false,
    description: undefined,
    tooltip: undefined,
    decimalPlaces: undefined,
    placeholder: undefined,
    labelValue: false,
    inputClasses: undefined,
    flag: false,
    appendInner: false,
    prependInner: false,
    append: false,
    min: undefined,
    labelField: 'label',
    valueField: 'value',
  }
)

const dateTimeMenu = ref(false)
const dateTimeFocus = ref(false)
const colorType = ref(false)

const dateTimeType = computed(
  () => props.type === 'time' || props.type === 'date'
)

const { label, required, type } = toRefs(props)
const attrs = useAttrs() as Record<string, unknown>

const emit = defineEmits(['update:modelValue', 'input', 'clickIcon'])
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    if (props.type === 'date' && value instanceof Date) {
      return emit('update:modelValue', value.toISOString().split('T')[0])
    }

    if (type.value !== 'number') {
      return emit('update:modelValue', value)
    }
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${props.decimalPlaces}})?`)
    emit('update:modelValue', value.toString().match(regex))
  },
})

const stringValue = computed({
  get: () => value.value?.toString() || '',
  set: (stringValue) => (value.value = stringValue),
})

const dateValue = computed({
  get: () => {
    if (typeof value.value === 'boolean') return null
    return value.value ? new Date(`${value.value}T00:00:00`) : null
  },
  set: (val) => {
    if (val instanceof Date) {
      value.value = val.toLocaleDateString('pt-BR')
    }
  },
})

const radioValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const checkValue = computed({
  get: () => props.modelValue,
  set: (newValue) => emit('update:modelValue', newValue),
})

const { class: classes, ...filteredAttrs } = attrs
defineOptions({
  inheritAttrs: false,
})

const { triggerRect, computedStyles, toggleMenu } = useMenuPosition()

const handleFocusMenu = (isOpen: boolean) => {
  dateTimeFocus.value = isOpen
  dateTimeMenu.value = isOpen
}
</script>

<template>
  <div class="flex w-full flex-col">
    <div
      v-if="!multiline && !['textarea'].includes(type) && props.label !== 'Até'"
      :class="`relative flex w-full items-stretch`"
    >
      <v-text-field
        v-if="type !== 'checkbox' && type !== 'radio' && type !== 'colorType'"
        v-model="value"
        v-bind="{ ...filteredAttrs }"
        variant="outlined"
        rounded="lg"
        density="comfortable"
        :hide-details="!description"
        :placeholder="dateTimeType ? (value ? '' : placeholder) : placeholder"
        :persistent-placeholder="Boolean(placeholder)"
        :type="
          type === 'time' && dateTimeFocus
            ? 'time'
            : type === 'date' && dateTimeFocus
              ? 'date'
              : type === 'time' || (type === 'date' && !value)
                ? 'text'
                : type
        "
        :min="min"
        :active="dateTimeType ? dateTimeMenu : false"
        :focus="dateTimeType ? dateTimeMenu : false"
        :disabled="disabled"
        :hint="description"
        :persistent-hint="!!description"
        :class="` mt-2 w-full bg-white accent-green transition-all duration-100
        ${error ? '!border-error' : '!border-grey-2'}
          ${disabled ? 'opacity-50' : ''}
          ${inputClasses ? inputClasses : ''}
          `"
        :maxlength="String($attrs?.maxlength ? $attrs?.maxlength : '1000')"
        @input="(e: Event) => $emit('input', e)"
        @blur="dateTimeFocus = false"
        @focus="handleFocusMenu(true)"
        @click:outside="handleFocusMenu(false)"
      >
        <template #label>
          <h6
            id="label"
            class="py-1 text-sm font-bold text-grey-2"
            :class="{
              '!text-error': error,
              '!text-grey-4': disabled,
              'w-11/12': tooltip,
            }"
          >
            {{ props.label }} {{ required ? '*' : '' }}
          </h6>
        </template>
        <v-menu
          v-if="dateTimeType"
          v-model="dateTimeMenu"
          :close-on-content-click="false"
          :persistent="false"
          activator="parent"
          @blur="dateTimeFocus = false"
          @click:outside="handleFocusMenu(false)"
        >
          <v-time-picker
            v-if="type === 'time'"
            v-model="value"
            hide-header
            format="24hr"
            :min="min"
            @update:model-value="dateTimeMenu = false"
          />
          <v-date-picker
            v-if="type === 'date'"
            v-model="dateValue"
            show-adjacent-months
            hide-header
            :min="min"
            color="green"
            @update:model-value="
              (newValue) => {
                if (newValue) {
                  value = new Date(newValue)
                }
                dateTimeMenu = false
              }
            "
          />
        </v-menu>
        <template v-if="prependInner" #prepend-inner>
          <img :src="props.icon" alt="" />
        </template>
        <template v-if="appendInner" #append-inner>
          <div class="appendWidth">
            <img :src="props.icon" alt="" width="24" />
          </div>
        </template>
        <template v-if="tooltip || append" #append>
          <div v-if="tooltip">
            <img
              id="tooltip"
              width="24"
              :src="greenInfoIcon"
              @mouseover="toggleMenu"
              @mouseout="toggleMenu"
            />
            <Teleport to="body">
              <TransitionRoot
                :show="Boolean(triggerRect)"
                enter="transition-opacity duration-75"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="transition-opacity duration-150"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  id="tooltip-text"
                  :style="computedStyles"
                  class="absolute z-50 mt-2 h-max w-max max-w-[30rem] origin-top-left divide-y break-words rounded-md bg-grey-1 p-2 font-light text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  {{ tooltip }}
                </div>
              </TransitionRoot>
            </Teleport>
          </div>
          <div v-if="append && icon">
            <button @click="$emit('clickIcon')">
              <img :src="props.icon" alt="" />
            </button>
          </div>
        </template>
      </v-text-field>
      <v-checkbox
        v-if="type === 'checkbox'"
        v-bind="{ ...filteredAttrs }"
        v-model="checkValue"
        :disabled="disabled"
        :hide-details="true"
        :label="label"
        density="comfortable"
        color="green"
        @update:model-value="(e) => $emit('input', e)"
      >
      </v-checkbox>
      <v-radio-group
        v-if="type === 'radio'"
        v-model="radioValue"
        density="comfortable"
        :hide-details="true"
      >
        <v-radio
          :value="value"
          :label="label"
          :hide-details="true"
          v-bind="{ ...filteredAttrs }"
          :disabled="disabled"
          color="green"
          @input="(e: Event) => $emit('input', e)"
        >
          <template #label>
            <p
              id="label"
              class="cursor-pointer"
              @click="
                () => {
                  if (!labelValue) {
                    value === label ? (value = '') : (value = label),
                      $emit('input', value === label ? undefined : label)
                  }
                }
              "
            >
              {{ label }}
            </p>
          </template>
        </v-radio>
      </v-radio-group>

      <v-menu
        v-if="type === 'colorType'"
        v-model="colorType"
        :close-on-content-click="false"
        activator="parent"
      >
        <template #activator="{ props: colorProps }">
          <v-text-field
            v-bind="colorProps"
            v-model="value"
            variant="outlined"
            rounded="lg"
            density="comfortable"
            class="color-input"
            :label="label"
            readonly
            hide-details
          >
            <template #append-inner>
              <div
                class="color-preview"
                :style="{ backgroundColor: String(stringValue) }"
              />
            </template>

            <template #label>
              <h6 id="label" class="py-1 text-sm font-bold text-grey-2">
                {{ props.label }}
              </h6>
            </template>
          </v-text-field>
        </template>

        <v-color-picker
          v-model="stringValue"
          hide-swatches
          @update:model-value="
            (newValue) => {
              if (newValue) value = newValue
            }
          "
        />
      </v-menu>

      <img
        v-if="icon && (type === 'checkbox' || type === 'radio')"
        :src="props.icon"
        alt=""
        :class="{ 'left-6': props.iconPosition === 'end' }"
        class="absolute ml-3 mt-1"
      />
    </div>
    <v-textarea
      v-else
      v-bind="{ ...filteredAttrs, class: classes }"
      v-model="stringValue"
      variant="outlined"
      class="bg-white"
      :label="label"
      :placeholder="placeholder"
      :hint="description"
      :hide-details="!description"
      :persistent-hint="!!description"
      :disabled="disabled"
      :maxlength="String($attrs?.maxlength ? $attrs?.maxlength : '1000')"
      :type="type"
      @input="(e: Event) => $emit('input', e)"
    >
      <template v-if="tooltip || append" #append>
        <img
          id="tooltip"
          width="24"
          :src="greenInfoIcon"
          @mouseover="toggleMenu"
          @mouseout="toggleMenu"
        />
        <Teleport to="body">
          <TransitionRoot
            :show="Boolean(triggerRect)"
            enter="transition-opacity duration-75"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity duration-150"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div
              id="tooltip-text"
              :style="computedStyles"
              class="absolute z-50 mt-2 h-max w-max max-w-[30rem] origin-top-left divide-y break-words rounded-md bg-grey-1 p-2 font-light text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {{ tooltip }}
            </div>
          </TransitionRoot>
        </Teleport>
      </template>
    </v-textarea>
  </div>
</template>
<style>
.v-input__details {
  background-color: transparent !important;
  padding-inline: 5px !important;
  width: 120% !important;
}

.appendWidth {
  width: 1.5rem;
}

.v-input--horizontal .v-input__append {
  margin-inline-start: 0px !important;
}

.color-preview {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.v-input__append {
  background-color: transparent !important;
}

input[type='time']::-webkit-calendar-picker-indicator {
  display: none !important;
}

input[type='date']::-webkit-calendar-picker-indicator {
  display: none !important;
}
</style>
