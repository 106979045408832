<script setup lang="ts">
import { VDataTable, VBtn, VIcon, VTextField, VChip } from 'vuetify/components'
import { ref, computed, defineEmits } from 'vue'
import { getMockColumnsEvents } from '@/modules/events/mocks/mockColumnEvents' // Mock das colunas
import { mockEvents } from '@/modules/events/mocks/mockEvents' // Mock dos eventos

const emit = defineEmits(['delete-item', 'update-item'])

const search = ref('')
const confirmDeleteDialog = ref(false)
const itemToDelete = ref<Record<string, unknown> | null>(null)

const confirmDelete = () => {
  if (itemToDelete.value) {
    emit('delete-item', itemToDelete.value?.id)
    confirmDeleteDialog.value = false
  }
}

// Filtra os itens com base na pesquisa
const filteredItems = computed(() => {
  if (!search.value) return mockEvents
  return mockEvents.filter((item) =>
    Object.values(item).some((value) =>
      String(value).toLowerCase().includes(search.value.toLowerCase())
    )
  )
})

// Mapeia as colunas corretamente para o Vuetify
const formattedHeaders = computed(() => {
  return getMockColumnsEvents().map((column) => ({
    title: column.title,
    key: column.key,
    sortable: column.sortable,
  }))
})

// Formata a data para o formato "dd/mm/aaaa"
const formatDate = (dateString: string | null | undefined) => {
  if (!dateString) return ''
  try {
    const [year, month, day] = dateString.split('-') // Assume formato "aaaa-mm-dd"
    return `${day}/${month}/${year}`
  } catch {
    return ''
  }
}

// Define as cores para os formatos
const formatColors: Record<string, string> = {
  Presencial: 'green',
  Online: 'blue',
  Híbrido: 'orange',
}

// Define as cores para a fase do evento
const phaseColors: Record<string, string> = {
  'Em planejamento': 'red',
  Divulgação: 'purple',
  Finalizado: 'blue',
  'Em andamento': 'orange',
  Cancelado: 'grey',
}
</script>

<template>
  <div class="flex flex-col gap-4">
    <!-- Campo de Pesquisa -->
    <v-text-field
      v-model="search"
      label="Pesquisar evento"
      variant="outlined"
      dense
      clearable
      class="w-1/3"
    />

    <!-- Tabela -->
    <v-data-table
      :headers="formattedHeaders"
      :items="filteredItems"
      items-per-page="10"
      class="flex h-full w-full overflow-hidden text-ellipsis whitespace-nowrap"
      density="compact"
    >
      <!-- ID -->
      <template #[`item.id`]="{ item }">
        <span>{{ item.id }}</span>
      </template>

      <!-- Nome -->
      <template #[`item.name`]="{ item }">
        <span>{{ item.name }}</span>
      </template>

      <!-- Data -->
      <template #[`item.date`]="{ item }">
        <div class="flex items-center gap-2">
          <v-icon size="small">mdi-calendar</v-icon>
          {{ formatDate(String(item.date)) }}
        </div>
      </template>

      <!-- Cidade -->
      <template #[`item.city`]="{ item }">
        <div class="flex items-center gap-2">
          {{ item.city }}
        </div>
      </template>

      <!-- Estado -->
      <template #[`item.state`]="{ item }">
        <span>{{ item.state }}</span>
      </template>

      <!-- País -->
      <template #[`item.country`]="{ item }">
        <span>{{ item.country }}</span>
      </template>

      <!-- Formato -->
      <template #[`item.format`]="{ item }">
        <v-chip
          :color="formatColors[item.format as string] || 'grey'"
          class="text-white"
        >
          {{ item.format }}
        </v-chip>
      </template>

      <!-- Publicação -->
      <template #[`item.publication`]="{ item }">
        <span>{{ item.publication }}</span>
      </template>

      <!-- Fase -->
      <template #[`item.phase`]="{ item }">
        <v-chip
          :color="phaseColors[item.phase as string] || 'grey'"
          class="text-white"
        >
          {{ item.phase }}
        </v-chip>
      </template>
    </v-data-table>
  </div>

  <!-- Diálogo de confirmação de exclusão -->
  <v-dialog v-model="confirmDeleteDialog" max-width="400px">
    <v-card>
      <v-card-title class="text-h5"> Confirmar Exclusão </v-card-title>
      <v-card-text>
        Tem certeza de que deseja excluir este evento?
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="grey" @click="confirmDeleteDialog = false">
          Cancelar
        </v-btn>
        <v-btn color="red" @click="confirmDelete"> Excluir </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
/* Estilos para a tabela */
.no-vertical-borders ::v-deep(.v-table__wrapper table) {
  border-collapse: collapse;
}

.no-vertical-borders ::v-deep(.v-table__wrapper table tr td),
.no-vertical-borders ::v-deep(.v-table__wrapper table tr th) {
  border-right: none !important;
  border-left: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.no-vertical-borders ::v-deep(.v-table__wrapper table tr td:first-child),
.no-vertical-borders ::v-deep(.v-table__wrapper table tr th:first-child) {
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.no-vertical-borders ::v-deep(.v-table__wrapper table tr td:last-child),
.no-vertical-borders ::v-deep(.v-table__wrapper table tr th:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
}
</style>
