<script lang="ts" setup>
const { title, elevation, mobileFab, isList } = defineProps({
  title: {
    type: String,
    required: true,
  },
  elevation: {
    type: [String, Number],
    default: 1,
  },
  mobileFab: {
    type: Boolean,
    default: false,
  },
  isList: {
    type: Boolean,
    default: false,
  },
})

const contentClasses = 'bg-surface'
</script>
<template>
  <v-app>
    <v-main class="d-flex flex-column min-h-100">
      <v-container fluid class="pa-0 d-flex flex-column flex-grow-1">
        <!-- Título da página -->
        <div
          v-if="isList"
          class="mx-sm-6 mt-sm-6 mx-0 rounded-t-lg bg-white"
          style="border-bottom: 1px solid #e0e0e0"
        >
          <div class="d-flex justify-space-between align-center px-4 py-4">
            <h1 class="text-h6 font-weight-medium ma-0">{{ title }}</h1>
            <div
              v-if="$slots['header-actions']"
              class="d-none d-sm-flex align-center"
            >
              <slot name="header-actions" />
            </div>
          </div>
        </div>

        <div v-else class="px-sm-6 pt-sm-6 mb-n2 px-4 pb-8 pt-4">
          <div class="d-flex justify-space-between align-center">
            <h1 class="text-h5 font-weight-medium">{{ title }}</h1>
            <div
              v-if="isList && $slots['header-actions']"
              class="d-none d-sm-flex"
            >
              <slot name="header-actions" />
            </div>
          </div>
        </div>

        <!-- Espaço no mobile entre header e conteúdo -->
        <div v-if="isList" class="d-sm-none" style="height: 16px" />

        <!-- Conteúdo principal -->
        <div class="flex-grow-1 d-flex flex-column mx-sm-6 mx-4">
          <v-sheet
            :elevation="elevation"
            class="pa-sm-6 rounded-b-lg px-4 pb-16 pt-6"
            :class="contentClasses"
          >
            <slot />
          </v-sheet>
        </div>

        <!-- Espaço reservado para o rodapé fixo não sobrepor conteúdo -->
        <div style="height: 96px" />

        <!-- Rodapé fixo com ações -->
        <div
          v-if="!isList && $slots['footer-actions']"
          class="footer-actions-wrapper"
        >
          <slot name="footer-actions" />
        </div>

        <!-- Botão flutuante para mobile -->
        <v-btn
          v-if="mobileFab && isList"
          class="fab-mobile-button d-sm-none"
          icon
          color="primary"
          fab
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped>
.min-h-100 {
  min-height: 100vh;
}

.footer-actions-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 16px;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  display: flex;
  justify-content: center;
  gap: 16px;
}

.fab-mobile-button {
  position: fixed;
  bottom: 96px; /* para não sobrepor o rodapé */
  right: 24px;
  z-index: 101;
}

.footer-btn {
  min-width: 140px;
  height: 44px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
</style>
