<script setup lang="ts">
import { ref, watch } from 'vue'
import EventCard from '@/components/EventCard.vue'
import noItens from '@/assets/images/noItens.png'

const emit = defineEmits(['delete-item', 'update-item', 'update'])

type GenericItem<T = Record<string, unknown>> = T

const props = withDefaults(
  defineProps<{
    columns?: Array<{
      title: string
      key: string
      width?: string
    }>
    items?: GenericItem[]
    viewActions?: boolean
    hasNextPage?: boolean
    itemsLength?: number
    currentPage?: number
    itemsPerPage?: number
    itemTitleKey?: string
  }>(),
  {
    columns: () => [],
    items: () => [],
    viewActions: false,
    itemsLength: 0,
    currentPage: 1,
    itemsPerPage: 10,
    itemTitleKey: 'name',
  }
)

const currentPage = ref(props.currentPage || 1)

watch(
  () => props.currentPage,
  (newPage) => {
    currentPage.value = Number(newPage)
  }
)

// 🔹 Formata datas corretamente
const formatDate = (dateString?: string) => {
  if (!dateString) return 'N/A'
  const date = new Date(dateString)
  return new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(date)
}

// 🔹 Obtém um identificador único para cada item
const getItemKey = (item: Record<string, unknown>): string | number => {
  return (
    (item.id as string | number) ||
    (item.uuid as string | number) ||
    crypto.randomUUID()
  )
}
</script>

<template>
  <v-container
    class="pa-4 flex min-h-[calc(100vh-120px)] flex-col justify-between"
    fluid
  >
    <div
      v-if="items.length === 0"
      class="flex w-full items-center justify-center"
      style="height: 706px"
    >
      <div
        class="flex w-full max-w-xl flex-col items-center px-0 text-center"
        style="word-break: break-word; overflow-wrap: break-word"
      >
        <img :src="noItens" alt="Empty illustration" />
        <h3 class="mb-2 py-4 text-xl font-semibold text-gray-800">
          {{ $t('events.empty.title') }}
        </h3>
        <p class="mb-4 text-sm leading-relaxed text-gray-600">
          {{ $t('events.empty.description') }}
        </p>
      </div>
    </div>

    <v-row v-else dense>
      <v-col
        v-for="item in items"
        :id="`event-container`"
        :key="getItemKey(item)"
        cols="12"
        sm="6"
      >
        <EventCard
          :id="`event-card-${item.id}`"
          :title="String(item.name)"
          :start-date="formatDate(String(item.startDate || ''))"
          :end-date="formatDate(String(item.endDate || ''))"
          :location="String(item.formattedLocation) || 'Local não disponível'"
          :format="String(item.format)"
          :status="String(item.status)"
          :stage="String(item.stage)"
          class="cursor-pointer"
          @click="emit('update-item', (item as any).id)"
        />
      </v-col>
    </v-row>

    <v-pagination
      v-if="items.length > 0"
      v-model="currentPage"
      :total-visible="3"
      :show-first-last-page="true"
      :length="
        Math.ceil(
          Number(props.itemsLength || 0) / Number(props.itemsPerPage || 10)
        )
      "
      class="custom-pagination"
      @update:model-value="
        emit('update', { page: $event, itemsPerPage: props.itemsPerPage })
      "
    />
  </v-container>
</template>

<style scoped>
.custom-pagination {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

::v-deep(.custom-pagination .v-pagination__list) {
  display: flex;
  gap: 4px;
}

::v-deep(.custom-pagination .v-pagination__item) {
  border-radius: 8px !important;
  min-width: 40px !important;
  height: 40px !important;
  width: 40px !important;
  font-size: 12px;
  font-weight: 500;
  background-color: white !important;
  color: black !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow:
    0px 2px 4px rgba(0, 0, 0, 0.1),
    0px 2px 8px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.2s;
}

::v-deep(.custom-pagination .v-pagination__item--is-active) {
  background-color: #8bc34a !important;
  color: white !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

::v-deep(.custom-pagination .v-pagination__more) {
  min-width: 40px !important;
  height: 40ch !important;
  width: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.6);
}

::v-deep(.custom-pagination .v-pagination__prev),
::v-deep(.custom-pagination .v-pagination__next),
::v-deep(.custom-pagination .v-pagination__first),
::v-deep(.custom-pagination .v-pagination__last) {
  border-radius: 8px !important;
  min-width: 40px !important;
  height: 40px !important;
  width: 40px !important;
  font-size: 12px;
  background-color: white !important;
  color: black !important;
  box-shadow:
    0px 2px 4px rgba(0, 0, 0, 0.1),
    0px 2px 8px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.2s;
}
</style>
