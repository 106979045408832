<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CropDialog from './CropDialog/CropDialog.vue'

const { t } = useI18n()
const imagePreview = ref<string | null>(null)
const fileInput = ref<HTMLInputElement | null>(null)
const snackbar = ref(false)
const snackbarMessage = ref('')
const dialogOpen = ref(false)

const props = defineProps<{ cropShape?: 'circle' | 'rectangle' }>()
const cropShape = props.cropShape || 'circle'

const selectFile = () => {
  fileInput.value?.click()
}

const dragOver = (event: DragEvent) => {
  event.dataTransfer!.dropEffect = 'copy'
}

const onDrop = (event: DragEvent) => {
  const file = event.dataTransfer!.files[0]
  handleFile(file)
}

const onFileChange = (event: Event) => {
  const file = (event.target as HTMLInputElement).files?.[0]
  if (file) handleFile(file)
}

const handleFile = (file: File) => {
  if (!file) return
  if (file.size > 2 * 1024 * 1024) {
    showSnackbar(t('errors.fileTooLarge'))
    return
  }
  if (!['image/png', 'image/jpeg', 'image/gif'].includes(file.type)) {
    showSnackbar(t('errors.invalidFormat'))
    return
  }

  const reader = new FileReader()
  reader.onload = (e) => {
    imagePreview.value = e.target?.result as string
    dialogOpen.value = true
  }
  reader.readAsDataURL(file)
}

const showSnackbar = (message: string) => {
  snackbarMessage.value = message
  snackbar.value = true
}

const handleSave = (croppedImage: string) => {
  imagePreview.value = croppedImage
  dialogOpen.value = false
}

const clearImage = () => {
  imagePreview.value = null
}
</script>

<template>
  <v-container>
    <v-snackbar v-model="snackbar" :timeout="3000" color="error" location="top">
      {{ snackbarMessage }}
    </v-snackbar>

    <v-card class="pa-4 d-flex flex-column align-center" outlined>
      <div
        v-if="!imagePreview"
        class="upload-container"
        @dragover.prevent="dragOver"
        @drop.prevent="onDrop"
      >
        <v-icon size="48">mdi-cloud-upload</v-icon>
        <p class="mt-2">
          {{ t('upload.prompt') }}
          <span class="select-text" @click="selectFile">{{
            t('upload.select')
          }}</span>
        </p>
        <v-file-input
          ref="fileInput"
          class="d-none"
          accept="image/png, image/jpeg, image/gif"
          show-size
          @change="onFileChange"
        ></v-file-input>
        <p class="file-info">{{ t('upload.formats') }}</p>
        <p class="file-info">{{ t('upload.maxSize') }}</p>
        <p class="file-info">{{ t('upload.recommendedResolution') }}</p>
      </div>
    </v-card>

    <CropDialog
      v-if="imagePreview"
      :open="dialogOpen"
      :image="imagePreview"
      :type="cropShape"
      @close="dialogOpen = false"
      @save="handleSave"
    />

    <div v-if="imagePreview && !dialogOpen" class="cropped-preview">
      <img
        :src="imagePreview"
        alt="Imagem cortada"
        class="cropped-image"
        :style="{ borderRadius: cropShape === 'circle' ? '50%' : '8px' }"
      />
      <v-btn color="error" class="mt-4" @click="clearImage">{{
        t('upload.removeImage')
      }}</v-btn>
    </div>
  </v-container>
</template>

<style scoped>
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ccc;
  padding: 40px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.select-text {
  color: #1976d2;
  cursor: pointer;
  font-weight: bold;
}
.file-info {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}
.cropped-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.cropped-image {
  max-width: 300px;
  border-radius: 8px;
  border: 2px solid #ccc;
}
</style>
