<script setup lang="ts"></script>

<template>
  <!-- Container que cobre a tela -->
  <v-container
    fluid
    class="position-absolute d-flex align-center fill-height pa-0 justify-center"
    style="z-index: 9999"
  >
    <!-- Loader circular com rotação manual -->
    <div
      class="rounded-pill"
      style="
        width: 80px;
        height: 80px;
        border: 4px solid var(--v-theme-grey-lighten-2);
        border-top-color: var(--v-theme-success);
        animation: spin 1s linear infinite;
      "
    ></div>
  </v-container>
</template>

<style scoped>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
